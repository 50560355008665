import React from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DateInput.css"

import { DateRangePicker } from '@dfds-ui/react-components';

const DateInput = (props) => {
    return (
        <div className = {props.ClassName}>
            <DateRangePicker
                onDateChange={props.OnDateChange}
                valid={props.Valid}
                startDates={{
                    initial: props.InitStart,
                    minDate: props.MinDate, 
                    maxDate: props.MaxDate, 
                }}
                endDates={{
                    initial: props.InitEnd,
                    minDate: props.MinDate,
                    maxDate: props.MaxDate, 
                }}
                labels={{ start: props.StartLabel, end: props.EndLabel, header: 'Please select a date' }}
            />
        </div>
    )

}
export default DateInput;