import React from 'react';
import Voyage from '../component/Voyage/Voyage'
import { pageChanged } from '../store/action/pageActions';
import { connect } from 'react-redux'

//Dummy for now just routing
const voyage = (props) => {
    const { pageChanged } = props

    pageChanged("VOYAGES")
    return <Voyage />
}
export default connect(null, { pageChanged })(voyage);