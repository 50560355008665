import { combineReducers } from 'redux';
import UserReducer from './userReducer';
import VoyageReducer from './voyageReducer'
import BookingReducer from './bookingReducer'
import PageReducer from './pageReducer';
import DeclarationReducer from './declarationReducer';
import AdminPanelReducer from './adminPanelReducer';

export default combineReducers({
    user: UserReducer,
    booking: BookingReducer,
    voyage: VoyageReducer,
    page: PageReducer,
    declaration: DeclarationReducer,
    adminPanel: AdminPanelReducer,
});