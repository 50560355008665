import React from 'react'
import { Modal } from '@dfds-ui/modal'
import PerfectTable from '../../CommonUI/Table/PerfectTable'
import { DeclarationHistoryStatusSchema } from './DeclarationStatusHistorySchema'
import './DeclarationStatusHistory.scss'
import { connect } from 'react-redux'
import { setSelectedRow } from '../../../store/action/declarationAction'
const DeclarationStatusHistoryModal = (props) => {
    const { SelectedRow, setSelectedRow } = props
    const _onClose = () => {
        setSelectedRow(null)
    }
    return (
        <>
            {
                SelectedRow && //it is impossible but better to have
                <Modal className="DeclarationHistoryModal"
                    isOpen={true}
                    heading={<span className="DeclarationModalTitle">{SelectedRow.tsn + " Status History"}</span>}
                    onRequestClose={() => { _onClose() }}>
                    <div className="gridContainer">
                        <PerfectTable
                            className="StatusHistoryTable"
                            DataArray={SelectedRow.statusHistory}
                            TableSchema={DeclarationHistoryStatusSchema}
                        />
                    </div>
                </Modal>
            }
        </>
    )
}
const mapStateToProps = state => {
    return {
        SelectedRow: state.declaration.SelectedRow
    }
}
export default connect(mapStateToProps, { setSelectedRow })(DeclarationStatusHistoryModal);
