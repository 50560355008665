import { decodeToken } from "./token";
import { getUserStorage } from './ens_storage'
export const isTokenExist = () => {
    const userStorage = getUserStorage();
    if (typeof userStorage.access_token === 'undefined' || userStorage.access_token === null || userStorage.access_token.trim() === "") {
        return false;
    }
    return true;
};

const getToken = () => {
    return localStorage.getItem('ENS_access_token');
};
export const getUsername = () => {
    const decodedToken = decodeToken(getToken());
    return decodedToken ? decodedToken['NameSurname'] : "Unknown"
}
export const getRoles = () => {
    const token = getToken()
    if (token) {
        const decodedToken = decodeToken(token);
        const userInfo = {
            roles: decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
        };

        return userInfo.roles;
    }
}
const getAuthorization = () => {
    const decodedToken = decodeToken(getToken());
    const userInfo = {
        name: decodedToken['NameSurname'],
        region: decodedToken['Region'],
        applicationName: decodedToken['ApplicationName'],
        roles: decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
    };
    return userInfo.roles;
};

export const hasAuthorizationToGo = (Component, auth) => {
    if (!auth)
        return true;

    const authArray = auth.split(",").map(function (item) {
        return item.toUpperCase().trim();
    });

    if (!authArray || authArray.length === 0)
        return true;

    const userAuthorization = getAuthorization();

    if (!userAuthorization)
        return false;

    if (!Array.isArray(userAuthorization)) {
        return authArray.includes(userAuthorization.toUpperCase());
    }

    return userAuthorization.some(r => authArray.includes(r.toUpperCase()));
};