
const commonLanguage = {
    tr: {

    },
    en: {
        ValidationMessage: "This field is required.",
        NoConnection: "No Connection."
    }
}
export const bookingPageLanguage = {
    tr: {

    },
    en: {
        NoBooking: "No bookings found.",
        NoConnection: commonLanguage.en.NoConnection,
        MatchStatusAll: "All",
        MatchedStatus: "Matched",
        UnmatchedStatus: "Unmatched",
        VoyageSummary: {
            Bookings: "Bookings : ",
            MBookings: "Matched Bookings : ",
            UMBookings: "Unm. Bookings : ",
            Ratio: "Matching Ratio : ",
            TsnCount: "TSN Count : ",
            TotalGrossWeight: "T.Weight : ",
            TotalBookingQuantity: "T.Quantity : ",
        },
        StatusCheck: {
            Red: "No declaration found.",
            Orange: "Check totals.",
            Green: "Everything is ok."
        },
        UnmatchDeclarationTable: {
            NoDeclaration: "No unmatched declaration.",
            NoConnection: commonLanguage.en.NoConnection,
        }
    }
}
export const voyagePageLanguage = {
    tr: {

    },
    en: {
        NoVoyageMessage: "No voyages found.",
        NoConnectionMessage: commonLanguage.en.NoConnection,
        StartDate: "Start Date",
        EndDate: "EndDate",
        VoyageHeader: "VOYAGES",
        VoyageValidationError: "Check date please. You cannot arrival before departure.",
        VoyageTimeValidationError: "You cannot search longer than 30 days",
    }
}
export const loginPageLanguage = {
    tr: {
        ValidationMessage: "Bu alan doldurulmak zorundadır.",

    },
    en: {
        ValidationMessage: commonLanguage.en.ValidationMessage,
        LoginHeader: "ENS Login",
        SignInButtonText: "SIGN IN",
        ForgotPassword: "Forgot Password?",
        UserNameHolder: "Username",
        PasswordHolder: "Password"

    }
}
export const NoAuthLanguage = {
    tr: {

    },
    en: {
        NoAuthTitle: "Permission Error",
        NoAuthMessage: "It seems you don't have an access to see this page.",
        NoAuthButtonLabel: "Go Back"
    }
}
export const forgotPasswordLanguage = {
    tr: {

    },
    en: {
        ForgotHeader: "Forgot Password",
        SuccessMessage: "Password reset information sent to your email.",
        ErrorMessage: "", // Gelen error'u aldığı gibi basıyor.
        ValidationMessage: commonLanguage.en.ValidationMessage,
        ButtonText: "SEND",
        UserNameHolder: "Username"
    }
}
export const resetPasswordLanguage = {
    tr: {

    },
    en: {
        ValidationMessage: commonLanguage.en.ValidationMessage,
        ButtonText: "RESET",
        ResetHeader: "Reset Password",
        SuccessMessage: "",
        ErrorMessage: "",
        ConfirmPasswordHolder: "Confirm Password",
        NewPasswordHolder: "New Password",
        InputErrorTitle: "Error",
        InputErrorMessage: "Password fields aren't same. Check your password.",
        NetworkErrorTitle: "Error",
        NetworkErrorMessage: "Something went wrong. Try again.",
        SuccessTitle: "Success",
        SuccessMessage: "Your password changed."
    }
}

