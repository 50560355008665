import React from 'react';
import SpinDiv from '../../CommonUI/Spindiv/Spindiv'
import NoBooking from '../../CommonUI/TableError/TableError';
import { BookingTableSchema } from './BookingTableSchema';
import PerfectTable from '../../CommonUI/Table/PerfectTable';
import { AlertWarning } from '@dfds-ui/icons';
import NetworkError from '../../CommonUI/TableError/TableError';
import { TsnTableSchema } from './TsnSubTableSchema'
import { connect } from 'react-redux';
import { setDeleteBookings } from '../../../store/action/bookingAction';
import { bookingPageLanguage } from '../../../assets/language/language'
import { getRoles } from '../../../core/authentication';
import {IsAdmin} from '../../../core/IsAdmin';

const language = "en";
const BookingTable = (props) => {
    const { GetBookingState, BookingData, OnRowClicks, StaticTable, matchStatus,
        OnCellClicks, ControlButtonEvents, onExcelClick, onExcelClose,
        VoyageNumber, showExcelModal, VoyageData, setDeleteBookings } = props
    return (
        tableContent(GetBookingState.bookingNotFound, GetBookingState.waitForRequest,
            BookingData, OnRowClicks, StaticTable, GetBookingState.noConnect
            , matchStatus, OnCellClicks, ControlButtonEvents, onExcelClick, onExcelClose, VoyageNumber, showExcelModal, VoyageData, setDeleteBookings)
    );
}


const tableContent = (bookingNotFound, waitForRequest, BookingData, rowClickEvents,
    StaticTable, noConnect, matchStatus, OnCellClicks, ControlButtonEvents, onExcelClick, onExcelClose, VoyageNumber, showExcelModal, VoyageData, setDeleteBookings) => {
    const NoConnectionIco = <AlertWarning className="searchLogo" width="50px" height="50px" />
    const rowDivs = [
        {
            show: bookingNotFound,
            component: <NoBooking ShowLogo={true} Message={bookingPageLanguage[language].NoBooking} />
        },
        {
            show: noConnect,
            component: <NetworkError Logo={NoConnectionIco} ShowLogo={true} Message={bookingPageLanguage[language].NoConnection} />
        },
        {
            show: waitForRequest,
            component: <SpinDiv waitForRequest={waitForRequest} ShowLogo={false} size={50} />
        },
    ]
    const FilterOptions = [
        {
            dataId: "declarations",
            queType: "CHECKLENGTH",
            desire: matchStatus,
            default: "All"
        }
    ]

    const TsnSubTable = {
        DataConnection: "declarations",
        DataArray: null,
        TableSchema: TsnTableSchema,
        OnRowClick: "",
        SubTable: "",
        FilterOptions: "",
        className: "subTableAnim",
        OnRowClick: rowClickEvents[1],
        OnCellClick: OnCellClicks[1],
        onControlBtnClick: (row, option) => ControlButtonEvents[1](row, option),
        Permission: getRoles()

    }
    const dataFilter = () => {
        if (matchStatus && BookingData) {
            if (matchStatus === "ALL") {
                return BookingData
            } else {
                let filteredData = {}
                Object.keys(BookingData).map((key) => {
                    if (BookingData[key].status.toUpperCase() === matchStatus.toUpperCase()) {
                        filteredData[key] = BookingData[key]
                    }
                })
                return filteredData
            }
        } else {
            return BookingData
        }
    }
    
    return (
        <div className={"bookingTableContent "}>
            <PerfectTable
                className={"BookingTable"}
                DataArray={dataFilter()}
                TableSchema={BookingTableSchema}
                OnRowClick={(Row) => rowClickEvents[0]}
                RowRenderComps={rowDivs}
                SubTable={TsnSubTable}
                // FilterOptions={FilterOptions}
                OnCellClick={OnCellClicks[0]}
                onControlBtnClick={(row) => ControlButtonEvents[0](row)}
                PermissionToDelete = {IsAdmin()}
                setCheckedData= {(Bookings,Checked) => { setDeleteBookings(Bookings,!Checked) }}
            />
        </div>
    );
}
const mapStateToProps = state => {
    return {
        matchStatus: state.booking.matchStatus,
    }
}
export default connect(mapStateToProps,{setDeleteBookings})(BookingTable);