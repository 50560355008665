import httpClient from "../../core/httpclient";
//import { spinnerService } from "@simply007org/react-spinners";
import userType from "../type/user";
import history from '../../core/history';
import {userLoginError}  from '../../core/errorHandler';
import ApiUrl from '../../core/url'
import {setUserStorage,getUserStorage,clearUserStorage} from '../../core/ens_storage'

export const authenticateUser = (User, setUser) =>async dispatch => {
    const endPoint = `${ApiUrl}/auth/authenticate`;

    const authenticateUserRequest = {
        Username: User.userName,
        Password: User.userPass
    }

    setUser({...User,waitForRequest : true})
    httpClient.post(endPoint, authenticateUserRequest)
        .then(response => {
            const userToken = response.data.result;
            //checkUserRole(userToken.access_token);
            setUserStorage(userToken);
            setUser({...User,waitForRequest : false}) //it is not important here
            dispatch({
                type: userType.USER_AUTHENTICATION_SUCCESS,
                token: response.data.result
            });
            setUser({
                ...User,
                ErrorMessage: "",
                ErrorCode: -1
            });
            history.push("/");
        })
        .catch(error => {
            setUser({...User,waitForRequest : false})
            userLoginError(error.request.status,User,setUser)
        })
        .finally(() => {
           
        });
}
export const userOnline = () => dispatch =>{
    const userStorage = getUserStorage()
    if(userStorage.access_token === undefined || userStorage.access_token === null || userStorage.access_token.trim() === "" ) return;
    dispatch({
        type: userType.USER_ONLINE,
        token : userStorage.access_token
    })
}
export const logOutUser = () => dispatch =>
{
    clearUserStorage();
    dispatch({
        type: userType.USER_LOGOUT
    })
}

