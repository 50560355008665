import React, { useState, useEffect } from 'react';
import './LoginForm.scss';
import { authenticateUser } from "../../store/action/userActions"
import { connect } from 'react-redux';
import UserInput from '../CommonUI/UserInput/UserInput'
import SendButton from '../CommonUI/SpinButton/SpinButton'
import ConnectionToServer from '../../core/connection'
import { Account, Lock } from '@dfds-ui/icons';
import { Modal } from '@dfds-ui/modal'
import ForgotPassword from '../ForgotPassword/ForgotPassword'
import {loginPageLanguage} from '../../assets/language/language'


const language = "en";

const LoginForm = ({ authenticateUser }) => {
    const [getUserState, setUserState] = useState({
        userName: "",
        userPass: "",
        userNameValid: true,
        userPassValid: true,
        ErrorMessage: "",
        ErrorCode: -1,
        waitForRequest: false,
    })
    const [showModal, setModal] = useState(true);
    const [ForgetPassModal, setForgetPassModal] = useState(false)

    const ForgetPass_OnClose = () => {
        setForgetPassModal(false)
    }
    const ForgetPass_Show = () => {
        setForgetPassModal(true)
    }
    useEffect(() => {
        if (getUserState.ErrorCode === 500 || getUserState.ErrorCode === 0)
            setModal(true)
        else
            setModal(false)

    }, [getUserState.ErrorCode])

    const calcHeight = () => {
        const formOffHeight = (!getUserState.userNameValid ? 25 : 0) + (!getUserState.userPassValid ? 25 : 0);
        const formHeight = 250;
        return formHeight + formOffHeight;
    }
    const inputArea = () => {
        return (
            <div className="inputArea">
                <UserInput
                    className={getUserState.userNameValid ? "Valid" : "Invalid"}
                    isValid={getUserState.userNameValid}
                    name='userName'
                    imgComp={<Account />}
                    holder={loginPageLanguage[language].UserNameHolder}
                    type='text'
                    onChange={(event) => setUserState({ ...getUserState, userName: event.target.value, userNameValid: true })}
                    value={getUserState.userName}
                    ValidationMessage = {loginPageLanguage[language].ValidationMessage}
                    onKeyPress={(event) => { if (event.key === "Enter") ConnectionToServer(getUserState, setUserState, authenticateUser) }}
                />
                <UserInput
                    className={getUserState.userPassValid ? "Valid" : "Invalid"}
                    isValid={getUserState.userPassValid}
                    name='userPass'
                    imgComp={<Lock />}
                    holder={loginPageLanguage[language].PasswordHolder}
                    type='password'
                    onChange={(event) => setUserState({ ...getUserState, userPass: event.target.value, userPassValid: true })}
                    value={getUserState.userPass}
                    ValidationMessage = {loginPageLanguage[language].ValidationMessage}
                    onKeyPress={(event) => { if (event.key === "Enter") ConnectionToServer(getUserState, setUserState, authenticateUser) }}
                />

            </div>
        );
    }

    const formHeader = () => {
        return (
            <div className="loginFormHeader">
                {loginPageLanguage[language].LoginHeader}
        </div>

        );
    }
    const formFooter = () => {
        return (
            <div className="loginFormFooter">
                <span id="forgetPass" onClick={ForgetPass_Show}>{loginPageLanguage[language].ForgotPassword}</span>
                <SendButton
                    waitForRequest={getUserState.waitForRequest}
                    onClick={() => ConnectionToServer(getUserState, setUserState, authenticateUser)}
                    ButtonLabel={loginPageLanguage[language].SignInButtonText}
                    className="signInBtn"
                />
            </div>
        );
    }
    return (
        <>
            {
                ForgetPassModal &&
                <ForgotPassword
                    ForgetPass_OnClose={ForgetPass_OnClose}
                />
            }

            <Modal heading={<h3>Error</h3>}
                isOpen={showModal}
                onRequestClose={() => { setModal(false); setUserState({ ...getUserState, ErrorCode: -1, ErrorMessage: "" }) }}>
                <p>{getUserState.ErrorMessage}</p>

            </Modal>

            <div className="Background"><div className="Background-cover"></div></div>

            <div style={{ height: calcHeight() + "px" }} className="loginForm">
                {formHeader()}
                {inputArea()}
                {formFooter()}
            </div>

        </>
    );
}
export default connect(null, { authenticateUser })(LoginForm);