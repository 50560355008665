import React from 'react';
import DateInput from '../../CommonUI/DateInput/DateInput'
import SearchButton from '../../CommonUI/DotAnimButton/DotAnimButton'
import { Select } from '@dfds-ui/react-components';
import { Down } from '@dfds-ui/icons';
import { timeRangeValid } from '../../../core/validationHandler'
import { JustOptionManager } from '../../CommonUI/JustOptionManager/JustOptionManager';


const VoyageTableHeader = ({
    GetVoyageState, SetVoyageState, FilterTable,
    VoyageData, StartDate,
    EndDate, ArrivalPort, DeparturePort, GetVoyages, SetVoyageDate, VoyagePorts
}) => {
    const ConfigurePorts = (isIt) => {
        if (!VoyagePorts) return
        let optionArray = []
        const optionMaker = (Ports) => {
            optionArray = Ports.map((P) => {
                let Port = {}
                Port.Value = P.portCode
                Port.Text = P.portName
                return Port
            })
        }
        if (isIt === "POL") {
            const polPorts = VoyagePorts.filter((P) => P.origin === "TR")
            optionMaker(polPorts)
        } else if (isIt === "POD") {
            const podPorts = VoyagePorts.filter((P) => P.origin === "EU")
            optionMaker(podPorts)
        }
        return optionArray
    }
    return (
        <div className="voyageTableHeader">
            <DateInput
                ClassName="datePickerContainer"
                InitStart={StartDate}
                InitEnd={EndDate}
                Valid={GetVoyageState.dateRangeValid}
                OnDateChange={(StartDate, EndDate) => {
                    SetVoyageState({
                        ...GetVoyageState,
                        dateRangeValid: true,
                    });

                    SetVoyageDate(StartDate, EndDate)
                }
                }
            />
            <Select
                className="departurePort"
                name="select"
                disabled={GetVoyageState.waitForRequest || !VoyageData || (VoyageData && Object.keys(VoyageData).length < 1)}
                onChange={(event) => {
                    SetVoyageState({ ...GetVoyageState, departurePort: event.target.value })
                    FilterTable(event.target.value, ArrivalPort, VoyageData, GetVoyageState, SetVoyageState);
                }}
                icon={<Down />}>

                <option value="" disabled selected>POL</option>
                {DeparturePort === "" ? <option value="" selected>ALL PORTS</option> : <option value="">ALL PORTS</option>}
                <JustOptionManager Options={ConfigurePorts("POL")} DefaultSelected={DeparturePort} />

            </Select>

            <Select
                className="arrivalPort"
                name="select"
                disabled={GetVoyageState.waitForRequest || !VoyageData || (VoyageData && Object.keys(VoyageData).length < 1)}
                onChange={(event) => {
                    SetVoyageState({ ...GetVoyageState, arrivalPort: event.target.value })
                    FilterTable(DeparturePort, event.target.value, VoyageData, GetVoyageState, SetVoyageState);
                }}
                icon={<Down />}>
                <option value="" disabled selected>POD</option>
                {ArrivalPort === "" ? <option value="" selected>ALL PORTS</option> : <option value="">ALL PORTS</option>}
                <JustOptionManager Options={ConfigurePorts("POD", ArrivalPort)} DefaultSelected={ArrivalPort} />
            </Select>

            <SearchButton
                className="searchBtn"
                ButtonLabel="SEARCH"
                add="ING"
                waitForRequest={GetVoyageState.waitForRequest}
                onClick={
                    () => searchClickEvent(GetVoyageState, SetVoyageState, StartDate,
                        EndDate, GetVoyages, ArrivalPort, DeparturePort, FilterTable)}
            />
        </div>
    );
}
const searchClickEvent = (getVoyageState, setVoyageState, StartDate, EndDate, GetVoyages, ArrivalPort, DeparturePort, FilterTable) => {
    const isTimeValid = timeRangeValid(StartDate, EndDate)
    if (!isTimeValid.valid) {
        setVoyageState({ ...getVoyageState, dateRangeValid: false, errorMessage: isTimeValid.errorMessage, showError: true })
        return;
    }
    GetVoyages(getVoyageState, setVoyageState, StartDate, EndDate, ArrivalPort, DeparturePort, FilterTable)
}
export default VoyageTableHeader;