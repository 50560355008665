import declarationTypes from '../type/declaration';
import { decreaseDate, increaseDate } from '../../core/dateStr';


const initialState = {
    Declarations: null,
    WaitForDeclarations: false,
    StartDate: null,
    EndDate: increaseDate(1),
    ReleaseNumber: null,
    UnitNumber: null,
    Tsn: null,
    NoConnection: false,
    DeclarationsNotFound: false,
    SelectedRow: null,
    SearchDeclarations: true,
    DeclarationErrors: {
        Code: -1,
        Title: "Error",
        Description: "",
    }
}
export default (state = initialState, action) => {
    switch (action.type) {
        case declarationTypes.GETTING_DECLARATIONS:
            return {
                ...state,
                WaitForDeclarations: true,
                NoConnection: false,
                DeclarationsNotFound: false,
                SearchDeclarations: false,
            }
        case declarationTypes.GET_DECLARATIONS:
            return {
                ...state,
                Declarations: action.Declarations,
                WaitForDeclarations: false
            }
        case declarationTypes.SET_SELECTED_ROW:
            return {
                ...state,
                SelectedRow: action.SelectedRow
            }
        case declarationTypes.DECLARATION_ERROR:
            return {
                ...state,
                WaitForDeclarations: false,
                DeclarationsNotFound: false,
                NoConnection: false,
                Declarations: null,
                SearchDeclarations: true,
                DeclarationErrors: {
                    Code: action.ErrorCode,
                    Title: action.ErrorTitle,
                    Description: action.ErrorDescription
                }
            }
        case declarationTypes.DECLARATIONS_NOT_FOUND:
            return {
                ...state,
                DeclarationsNotFound: true,
                WaitForDeclarations: false,
                NoConnection: false,
                Declarations: null
            }
        case declarationTypes.SET_DECLARATION_DATE:
            return {
                ...state,
                StartDate: action.StartDate,
                EndDate: action.EndDate,
            }
        case declarationTypes.SET_DECLARATION_TSN:
            return {
                ...state,
                Tsn: action.Tsn
            }
        case declarationTypes.SET_DECLARATION_UNITNUMBER:
            return {
                ...state,
                UnitNumber: action.UnitNumber
            }
        case declarationTypes.SET_DECLARATION_RELEASENUMBER:
            return {
                ...state,
                ReleaseNumber: action.ReleaseNumber
            }
        case declarationTypes.CLEAR_DECLARATION_PAGE:
            return {
                ...initialState
            }
        default:
            return state;

    }
}

