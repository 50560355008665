export const setUserStorage = item => {
    localStorage.setItem("ENS_access_token",item.access_token)
    localStorage.setItem("ENS_reflesh_token", item.refresh_token)
    localStorage.setItem("ENS_expires_in",item.expires_in)
}
export const getUserStorage = () => {
    return {
        access_token : localStorage.getItem("ENS_access_token"),
        refresh_token : localStorage.getItem("ENS_reflesh_token"),
        expires_in : localStorage.getItem("ENS_expires_in")
    }
}
export const clearUserStorage = () => {
    localStorage.setItem("ENS_access_token","")
    localStorage.setItem("ENS_reflesh_token", "")
    localStorage.setItem("ENS_expires_in","")
    localStorage.setItem("ENS_VOYAGEID_BOOKING","")
}
export const checkUserStorage = () => {
    const userStorage = getUserStorage();
    console.log("Access Token")
    console.log(userStorage.access_token)
    console.log("Refresh Token")
    console.log(userStorage.refresh_token)
    console.log("Expires In")
    console.log(userStorage.expires_in)
}
