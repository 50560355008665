import userTypes from '../type/user';


const initialState = {
    token: null
}
export default (state = initialState, action) => {
    switch (action.type) {
        case userTypes.USER_AUTHENTICATION_SUCCESS:
            return {
                ...state,
                token: {
                    ...action.token
                }
            }
        case userTypes.USER_LOGOUT:
            return {
                ...state,
                token: null
            }
        case userTypes.USER_ONLINE:
            return {
                ...state,
                token: {
                    ...action.token
                }
            }
        default:
            return state;
    }
}