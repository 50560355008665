import React from 'react';
import { FaRegFrown } from "react-icons/fa";
import { Search } from '@dfds-ui/icons';
import NoFound from '../TableError/TableError'

const FilterNotFound = (props) => {
    const {Message} = props;
    return(
        <NoFound ShowLogo={true} Message={Message} />
    );
}
/**
 * <div className="FilterNotFound">
            <Search className="FilterNotFoundIcon"/>
            <span className="FilterNotFoundText">No result</span>
        </div>
 */
export default FilterNotFound;