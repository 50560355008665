import React from 'react'
import { Modal } from '@dfds-ui/modal'
import { Button } from '@dfds-ui/react-components';
import { LeftArrow } from '@dfds-ui/icons';

export const ErrorModal = (props) => {
    return (
        <Modal isOpen={true} onRequestClose={props.OnClose} heading={<span className="headerTitle">{props.ErrorTitle}</span>}>
            <p>{props.ErrorMessage}</p>
            <Button
                onClick={props.ButtonOnClick ? props.ButtonOnClick : props.OnClose}
                variation="secondary"
                icon={<LeftArrow />}
                iconAlign="left">
                {props.ButtonLabel ? props.ButtonLabel : "Go Back"}
            </Button>
        </Modal>
    );
}
//Permission Error
//It seems you don't have an access to see this page.