import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isTokenExist, hasAuthorizationToGo } from '../../core/authentication';

const privateRoute = ({ component: Component, ...rest }) => {


     if (!isTokenExist()) {
        //const afterLogin = rest.location.pathname ? `?afterLogin=${rest.location.pathname}` : '';
        return <Route {...rest} render={props => (
            <Redirect to={'/login'}/>
        )} />
    }

   if(!hasAuthorizationToGo(Component, rest.auth)){
        return <Route {...rest} render = { props => (
            <Redirect to={{pathname: '/noAuthorization', state: {from: props.location}}}/>
        )}/>
    }

    return <Route {...rest} render={props => (
        <Component {...props} />
    )} />
};

export default privateRoute;