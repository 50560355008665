import React, { useEffect } from 'react'
import './Declaration.scss'
import DeclarationHeader from './DeclarationHeader/DeclarationHeader'
import DeclarationTable from './DeclarationTable/DeclarationTable'
import { getDeclarations, clearPage, clearError } from '../../store/action/declarationAction'
import DeclarationStatusHistoryModal from './DeclarationStatusHistory/DeclarationStatusHistory'
import { connect } from 'react-redux'
import { ErrorModal } from '../CommonUI/Modals/ErrorModal'
const DeclarationPage = (props) => {
    const { StartDate, EndDate, getDeclarations, SelectedRow, clearPage, DeclarationErrors, clearError } = props
    useEffect(() => {
        // getDeclarations(StartDate, EndDate)
        return () => { clearPage() }
    }, [])
    return (
        <div className="DeclarationsPage-Container">
            {
                SelectedRow &&
                <DeclarationStatusHistoryModal />
            }
            <DeclarationHeader />
            <DeclarationTable />
            {
                DeclarationErrors.Code > -1 &&
                <ErrorModal OnClose={clearError} ErrorTitle={DeclarationErrors.Title} ErrorMessage={DeclarationErrors.Description} />
            }
        </div>
    )
}
const mapStateToProps = state => {
    return {
        StartDate: state.declaration.StartDate,
        EndDate: state.declaration.EndDate,
        SelectedRow: state.declaration.SelectedRow,
        DeclarationErrors: state.declaration.DeclarationErrors
    }
}

export default connect(mapStateToProps, { getDeclarations, clearPage, clearError })(DeclarationPage);