import React, { useEffect } from 'react'
import './IdentityUser.scss'
import { Button } from '@dfds-ui/react-components'
import { JustTable } from '../../../component/CommonUI/JustTable/JustTable'
import { IdentityUserTableSchema, DummyCreator } from './IdentityUserTableSchema'
import { IdentityUserModalShow, clearNetworkError, clearError, setSelectedUser, deleteModalShow, deleteModalHide, getUsers, deleteUser } from '../../../store/action/adminPanelAction'
import DeleteModal from '../../CommonUI/Modals/YesNoModal/YesNoModal';
import { ErrorModal } from '../../CommonUI/Modals/ErrorModal';
import IdentityModal from './Modal/Modal'
import { connect } from 'react-redux'

const IdentityUser = (props) => {
    const { display, IdentityUserModalShow, setSelectedUser, ShowDeleteModal, ErrorHandler, clearNetworkError, clearError,
        WaitForDeleteUser, Language, deleteModalShow, deleteModalHide, Users, WaitForUsers, getUsers, SelectedUser, deleteUser, RefreshUserPage, IdentityUsername } = props
    const JustRowContextMenu = [
        {
            Name: "editUser",
            Action: (Row) => {
                setSelectedUser(Row)
            }
        },
        {
            Name: "deleteUser",
            Action: (Row) => {
                deleteModalShow(Row)
            }
        }
    ]
    useEffect(() => {
        if (display) {
            getUsers()
        }
    }, [display])

    useEffect(() => {
        if (RefreshUserPage) {
            getUsers()
        }
    }, [RefreshUserPage])

    const JustAnimations = {
        CanvasWidth: 1255,
        WaitForRequest: WaitForUsers
    }

    const ErrorCode = ErrorHandler && ErrorHandler.ErrorCode;
    const ErrorDescription = ErrorHandler && ErrorHandler.ErrorDescription
    const ErrorState = ErrorHandler && ErrorHandler.ErrorState

    const _onDeleteUserClick = () => {
        //debugger
        deleteUser(IdentityUsername)
    }

    return (
        display &&
        <>
            <div className="IdentityUser-Container">
                <div className="IdentityUser-Header">
                    <h4 id="HeaderText">User Management</h4>
                </div>
                <div className="IdentityUser-Content-Container">
                    <div className="IdentityUser-Content-Header">
                        <Button
                            className="IdentitiyUser-AddUser"
                            onClick={() => IdentityUserModalShow()}
                            disabled={false}
                        >
                            New User
                    </Button>
                    </div>
                    <div className="IdentityUser-Table-Container">
                        <JustTable
                            JustSchema={IdentityUserTableSchema()}
                            JustData={Users}
                            JustAnimations={JustAnimations}
                            JustContextFunctions={JustRowContextMenu}
                        />
                    </div>

                </div>
            </div>
            {
                (ErrorCode !== -1) &&
                <ErrorModal
                    ButtonLabel={"Go Back"}
                    ErrorTitle={"Error"}
                    ErrorMessage={ErrorDescription}
                    OnClose={() => {
                        switch (ErrorState) {
                            case "NETWORK_ERROR":
                                clearNetworkError()
                                break;
                            case "CUSTOM_ERROR":
                                clearError()
                                break;
                            default:
                                clearError()
                                break;
                        }
                    }}
                />
            }
            <IdentityModal />
            <DeleteModal
                OpenModal={ShowDeleteModal}
                Title={"Delete User"}
                Message={"User will be deleted. Click yes to continue."}
                OnClose={() => deleteModalHide()}
                onYesClick={(e) => { _onDeleteUserClick() }}
                waitForRequest={WaitForDeleteUser}
                ButtonYesText={"Yes"}
                ButtonNoText={"No"}
            />
        </>

    )
}
const mapStateToProps = (state) => {
    return {
        ShowDeleteModal: state.adminPanel.ShowDeleteModal,
        WaitForDeleteUser: state.adminPanel.WaitForDeleteUser,
        WaitForUsers: state.adminPanel.WaitForUsers,
        Users: state.adminPanel.Users,
        // Language: state.user.Language,
        ErrorHandler: state.adminPanel.ErrorHandler,
        SelectedUser: state.adminPanel.SelectedUser,
        IdentityUsername: state.adminPanel.IdentityUsername,
        RefreshUserPage: state.adminPanel.RefreshUserPage,

    }
}
export default connect(mapStateToProps, {
    IdentityUserModalShow, setSelectedUser, deleteModalShow,
    deleteModalHide, getUsers, clearNetworkError, clearError, deleteUser
})(IdentityUser);