import React from 'react';
import Declaration from '../component/Declaration/Declaration'
import { pageChanged } from '../store/action/pageActions';
import { connect } from 'react-redux'

//Dummy for now just routing
const declaration = (props) => {
    const { pageChanged } = props
    pageChanged("DECLARATIONS")
    return <Declaration />
}
export default connect(null, { pageChanged })(declaration);