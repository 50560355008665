import React, { useEffect } from 'react'
import './Modal.scss'
import { Modal } from '@dfds-ui/modal'
import { connect } from 'react-redux'
import {
    IdentityUserModalHide, getUserRoles, saveUser
    , setInputIdentityUsername, setInputIdentityName, setInputIdentityPassword, setInputIdentitySurname, setInputIdentityEmail,
    setInputIdentityRole, setInputIdentityStatus, setInputIdentityCompanyCode, setInputIdentityDepartment
} from '../../../../store/action/adminPanelAction'
import { Input, Button, SelectField } from '@dfds-ui/react-components'

const IdentityModal = (props) => {
    const { IdentityUserModalHide, ShowIdentityUserModal, SelectedUser, Roles,
        getUserRoles, saveUser, IdentityUsername, IdentityName, IdentitySurname,
        IdentityEmail, IdentityRole, IdentityStatus, IdentityCompanyCode, IdentityDepartment, IdentityPassword
        , setInputIdentityUsername, setInputIdentityName, setInputIdentityPassword, setInputIdentitySurname, setInputIdentityEmail,
        setInputIdentityRole, setInputIdentityStatus, setInputIdentityCompanyCode, setInputIdentityDepartment
    } = props
    useEffect(() => {
        if (ShowIdentityUserModal) {
            getUserRoles()
        }
    }, [ShowIdentityUserModal])
    return (
        <Modal className="IdentityUser-Modal" heading={SelectedUser ? "Edit User" : "New User"} onRequestClose={() => IdentityUserModalHide()} isOpen={ShowIdentityUserModal}>
            <div className="IdentityUser-Modal-Container">
                <div className="IdentityUser-Modal-Header">

                </div>
                <div className="IdentityUser-Modal-Content">
                    <div className="RightContent-Container">
                        <div className="InputWithLabel" id="username">
                            <span className="Input-Label">
                                Username
                        </span>
                            {
                                <Input
                                    size={"small"}
                                    onChange={(event) => setInputIdentityUsername(event.target.value)}
                                    value={SelectedUser && SelectedUser.username}
                                    disabled={SelectedUser}
                                />
                            }
                        </div>
                        <div className="InputWithLabel" id="firstName">
                            <span className="Input-Label">
                                First Name
                        </span>

                            <Input
                                onChange={(event) => { setInputIdentityName(event.target.value); }}
                                defaultValue={SelectedUser && SelectedUser.name}
                                size={"small"}
                            />
                        </div>
                        <div className="InputWithLabel" id="lastName">
                            <span className="Input-Label">
                                Last Name
                        </span>
                            <Input
                                onChange={(event) => { setInputIdentitySurname(event.target.value); }}
                                defaultValue={SelectedUser && SelectedUser.surname}
                                size={"small"}
                            />
                        </div>
                        <div className="InputWithLabel" id="email">
                            <span className="Input-Label">
                                E-Mail
                        </span>
                            <Input
                                onChange={(event) => { setInputIdentityEmail(event.target.value); }}
                                defaultValue={SelectedUser && SelectedUser.email}
                                size={"small"}
                            />
                        </div>
                    </div>
                    <div className="LeftContent-Container">

                        <div className="InputWithLabel" id="companyCode">
                            <span className="Input-Label">
                                Company Code
                            </span>
                            <Input
                                onChange={(event) => { setInputIdentityCompanyCode(event.target.value); }}
                                defaultValue={SelectedUser && SelectedUser.companyCode}
                                size={"small"}
                            />
                        </div>
                        <div className="InputWithLabel" id="department">
                            <span className="Input-Label">
                                Department
                            </span>
                            <Input
                                onChange={(event) => { setInputIdentityDepartment(event.target.value); }}
                                defaultValue={SelectedUser && SelectedUser.department}
                                size={"small"}
                            />
                        </div>
                        <div className="InputWithLabel" id="roles">
                            <span className="Input-Label">
                                Roles
                        </span>
                            <SelectField
                                onChange={(event) => { setInputIdentityRole(event.target.value); }}
                                defaultValue={SelectedUser && SelectedUser.roles[0]}
                                size="small"
                            >
                                {Roles.map((Role) => {
                                    return <option value={Role}>{Role}</option>
                                })}
                            </SelectField>
                        </div>
                    </div>


                </div>
                <div className="IdentityUser-Modal-Footer">
                    <Button
                        className="IdentityUser-Button-Save"
                        disabled={false}
                        onClick={() => {
                            saveUser(IdentityUsername, IdentityName, IdentitySurname, IdentityEmail, IdentityRole,
                                IdentityStatus, IdentityCompanyCode, IdentityDepartment, IdentityPassword)
                        }}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </Modal>
    )
}



const mapStateToProps = (state) => {
    return {
        ShowIdentityUserModal: state.adminPanel.ShowIdentityUserModal,
        SelectedUser: state.adminPanel.SelectedUser,
        Roles: state.adminPanel.Roles,
        IdentityUsername: state.adminPanel.IdentityUsername,
        IdentityName: state.adminPanel.IdentityName,
        IdentitySurname: state.adminPanel.IdentitySurname,
        IdentityEmail: state.adminPanel.IdentityEmail,
        IdentityRole: state.adminPanel.IdentityRole,

        IdentityStatus: state.adminPanel.IdentityStatus,
        IdentityCompanyCode: state.adminPanel.IdentityCompanyCode,
        IdentityDepartment: state.adminPanel.IdentityDepartment,
        IdentityPassword: state.adminPanel.IdentityPassword,
    }
}
export default connect(mapStateToProps, {
    IdentityUserModalHide, getUserRoles, saveUser
    , setInputIdentityUsername, setInputIdentityName, setInputIdentityPassword, setInputIdentitySurname, setInputIdentityEmail,
    setInputIdentityRole, setInputIdentityStatus, setInputIdentityCompanyCode, setInputIdentityDepartment
})(IdentityModal)