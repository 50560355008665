import React from 'react'

import HistoryButton from '../../CommonUI/SpinButton/SpinButton'

export const DeclarationTableSchema = {
    ColumnDesign: [
        {
            dataId: "provider",
            text: "Service Provider Name",
        },
        {
            dataId: "statusName",
            text: "Declaration Status"
        },
        {
            dataId: "tsn",
            text: "TSN Number",
        },
        {
            dataId: "declarationDateEpoch",
            text: "Declaration Date",
        },
        {
            dataId: "unitNumber",
            text: "Unit Number",
        },
        {
            dataId: "totalNumberOfPackages",
            text: "Packages",
            // infoText: "Total Number of Package",
        },
        {
            dataId: "totalGrossWeight",
            text: "Weight",
            // infoText: "Total number of weight",
        },
        {
            dataId: "voyageNumber",
            text: "Voyage Number"
        },
        {
            dataId: "departureDateEpoch",
            text: "Voyage Departure Date",
            // infoText: "Estimated Time of Arrival",
        },
        {
            dataId: "releaseNumber",
            text: "Release Number",
        },
    ],
    DataDesign: [
        {
            dataId: "provider",
        },
        {
            dataId: "statusName",
            showData: false,
            multipleControl: true,
            controlIds: [
                "StatusName"
            ],
            controlResults: {
                forAction: (controls, onClick, Row, Permission) => HistoryRender(controls, onClick, Row, Permission),
            },
        },
        {
            dataId: "tsn",
        },
        {
            dataId: "declarationDateEpoch",
            type: "datetime",
        },

        {
            dataId: "unitNumber",
        },
        {
            dataId: "totalNumberOfPackages",
            type: "number",
            digit: 0,
        },
        {
            dataId: "totalGrossWeight",
            type: "number",
        },
        {
            dataId: "voyageNumber"
        },
        {
            dataId: "departureDateEpoch",
            type: "datetime",
        },
        {
            dataId: "releaseNumber",
        },
    ]
}
const HistoryRender = (controls, onClick, Row, Permission) => {
    return <span id="Row-History" onClick={(e) => onClick(e, Row)}>{Row.statusName}</span>
    // return <HistoryButton className="Row-HistoryButton" ButtonLabel={"HISTORY"} onClick={(e) => onClick(e, Row)} />
}
export const DummyDeclaration = [
    {
        provider: "Furkan A.Ş.  ltd. şti. ",
        tsn: 123456,
        releaseNumber: 1234567889,
        unitNumber: 123123213,
        declarationDateEpoch: 1534326784,
        departureDateEpoch: 1534326784,
        totalNumberOfPackages: 5,
        totalGrossWeight: 5500,
        statusName: "CustomCleared",
        statusHistory: [
            {
                statusName: "CustomCleared",
                updateDateEpoch: 1534326784
            },
            {
                statusName: "Matched",
                updateDateEpoch: 1534326784
            },
            {
                statusName: "Entered",
                updateDateEpoch: 1534326784
            },
        ]
    },
    {
        tsn: 321321,
        releaseNumber: 1234567889,
        unitNumber: 123123213,
        provider: "Furkan",
        declarationDateEpoch: 1534326784,
        departureDateEpoch: 1534326784,
        totalNumberOfPackages: 5,
        totalGrossWeight: 5500,
        statusName: "CustomCleared",
        statusHistory: [
            {
                statusName: "CustomCleared",
                updateDateEpoch: 1534326784
            },
            {
                statusName: "Matched",
                updateDateEpoch: 1534326784
            },
            {
                statusName: "Entered",
                updateDateEpoch: 1534326784
            },
        ]
    },
    {
        tsn: 123123,
        releaseNumber: 1234567889,
        unitNumber: 123123213,
        provider: "Furkan ltd. şti. ",
        declarationDateEpoch: 1534326784,
        departureDateEpoch: 1534326784,
        totalNumberOfPackages: 5,
        totalGrossWeight: 5500,
        statusName: "CustomCleared",
        statusHistory: [
            {
                statusName: "CustomCleared",
                updateDateEpoch: 1534326784
            },
            {
                statusName: "Matched",
                updateDateEpoch: 1534326784
            },
            {
                statusName: "Entered",
                updateDateEpoch: 1534326784
            },
        ]
    }
]