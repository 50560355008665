import React from 'react';
import axios from 'axios';

const Sd = () => {
    const button_onClick = () => {
        const str = "http://localhost:5812/GetMessage/HelloWorld";

        var postData = null

        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                crossorigin: true,
                'Access-Control-Allow-Credentials': true
            }
        };

        axios.post(str, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            })
    }
    return (
        <>
            <button onClick={button_onClick}>Gonder</button>
        </>
    )

}


export default Sd;