import React from 'react'
import { FaCircle } from "react-icons/fa";
import MatchButton from '../../CommonUI/SpinButton/SpinButton'

import { bookingPageLanguage } from '../../../assets/language/language'
const language = "en"

export const BookingTableSchema = {
    SubTableCalculation: [
        {
            SubColumnId: "totalNumberOfPack",
            DataId: "declarationsTotalPackage",
            digit: 0,
        },
        {
            SubColumnId: "totalGrossMass",
            DataId: "declarationsGrossWeight",
            digit: 2
        }
    ],
    CheckTable: true,
    ColumnDesign: [
        {
            dataId: "releaseNumber",
            text: "Release Number",
            filter: "text",
            filterPlaceHolder: "Filter for Release Number",
        },
        {
            dataId: "unitType",
            text: "Unit Type",
        },
        {
            dataId: "unitNumber",
            text: "Unit Number",
            filter: "text",
            filterPlaceHolder: "Filter for Unit Number",

        },
        {
            dataId: "vehicleRegisterNumber",
            text: "VRN",
            infoText: "Vehicle Register Number",
            filter: "text",
            filterLabelText: "Vehicle Register Number",
            filterPlaceHolder: "Filter for VRN",
        },
        {
            dataId: "customerName",
            text: "Customer Name",
            filter: "text",
            filterPlaceHolder: "Filter for Customer Name",
        },
        {
            dataId: "senderName",
            text: "Sender Name",
        },
        {
            dataId: "consigneeName",
            text: "Consignee Name",
        },
        {
            dataId: "totalPackage",
            text: "Total Packages",
        },
        {
            dataId: "grossWeight",
            text: "Total Weights",
        },
        {
            dataId: "declarations",
            text: "Status",
            infoText: <>
                <div className="expOfColours">
                    <FaCircle className="controlGreen" />
                    {bookingPageLanguage[language].StatusCheck.Green}
                </div>
                <div className="expOfColours">
                    <FaCircle className="controlOrange" />
                    {bookingPageLanguage[language].StatusCheck.Orange}
                </div>
                <div className="expOfColours">
                    <FaCircle className="controlRed" />
                    {bookingPageLanguage[language].StatusCheck.Red}
                </div>
            </>
        },
        {
            dataId: "statusButton",
            text: "",
        },
    ],
    DataDesign: [
        {
            dataId: "releaseNumber",
            controlId: "releaseNumberForDelete"
        },
        {
            dataId: "unitType",
        },
        {
            dataId: "unitNumber",
        },
        {
            dataId: "vehicleRegisterNumber",
        },
        {
            dataId: "customerName",
        },
        {
            dataId: "senderName",
        },
        {
            dataId: "consigneeName",
        },
        {
            dataId: "totalPackage",
            controlId: "totalPackageMatch",
            type: "number",
            digit: 0,
            control: "bool",
            showData: true,
            controlResult: {
                forFalseClass: " falseClass",
                forTrueClass: " trueClass"
            }
        },
        {
            dataId: "grossWeight",
            controlId: "grossWeightMatch",
            control: "bool",
            type: "number",
            // digit: 3,
            showData: true,
            controlResult: {
                forFalseClass: " falseClass",
                forTrueClass: " trueClass"
            }

        },
        {
            dataId: "declarations",

            multipleControl: true,
            controlIds: [
                "matchStatus", "grossWeightMatch", "totalPackageMatch"
            ],
            controlResults: {
                forAction: (controls, onClick, Row) => statusFinalIcon(controls, onClick, Row),
            },
            showData: false,
        },
        {
            dataId: "statusButton",
            multipleControl: true,
            controlIds: [
                "matchStatus", "grossWeightMatch", "totalPackageMatch"
            ],
            controlResults: {
                forAction: (controls, onClick, Row) => statusFinalButton(controls, onClick, Row),
            },
            showData: false,

        },
    ]
}

const statusFinalButton = (controls, onClick, Row) => {
    let trueCount = 0;
    let importantControl = false;
    controls.forEach((control, index) => {
        if (index === 0) importantControl = control;
        trueCount = (control) ? trueCount + 1 : trueCount;
    })
    if (controls.length === trueCount) {
    }
    else if (importantControl) {
        const matchButton = <MatchButton ButtonLabel="MATCH" onClick={() => onClick(Row)} className="matchButton PosMiddle" />
        return [matchButton]
    }
    else if (controls.length >= trueCount) {
        const matchButton = <MatchButton ButtonLabel="MATCH" onClick={() => onClick(Row)} className="matchButton PosMiddle" />
        return [matchButton]
    }
}
const statusFinalIcon = (controls, onClick, Row) => {
    let trueCount = 0;
    let importantControl = false;
    controls.forEach((control, index) => {
        if (index === 0) importantControl = control;
        trueCount = (control) ? trueCount + 1 : trueCount;
    })
    if (controls.length === trueCount) {
        return <FaCircle className="controlIconTrue PosMiddle" />
    }
    else if (importantControl) {
        const DangerCircle = <FaCircle data-tip data-for={Row.releaseNumber + " tooltip"} className="controlIconFalse dangerColour PosMiddle" />
        return [DangerCircle]
    }
    else if (controls.length >= trueCount) {
        const WarningCircle = <FaCircle data-tip data-for={Row.releaseNumber + " tooltip"} className="controlIconFalse PosMiddle" />
        return [WarningCircle]
    }
}