import httpClient from "../../core/httpclient";
import voyageType from "../type/voyage";
import ApiUrl from '../../core/url'
import { increaseDate } from '../../core/dateStr';

export const getVoyage = (getVoyageState, setVoyageState, StartDate, EndDate, ArrivalPort, DeparturePort, FilterTable) => async dispatch => {
    const endPoint = `${ApiUrl}/voyage/getvoyages`;
    if (StartDate === null || EndDate === null || StartDate === undefined || EndDate === undefined) return;
    const eDate = increaseDate(1, new Date(EndDate));
    const voyageRequest = {
        departureDateBegin: StartDate.split('-').join(''),
        departureDateEnd: EndDate.split('-').join(''),
        departurePort: null,
        arrivalPort: null
    }
    const voyageRequestOrigin = { //I'm absolutly going to change this
        StartDate: StartDate,
        EndDate: EndDate,
        DeparturePort: DeparturePort,
        ArrivalPort: ArrivalPort
    }
    setVoyageState({ ...getVoyageState, waitForRequest: true, voyageNotFound: false, notSearched: false, noConnect: false })
    httpClient.post(endPoint, voyageRequest)
        .then(response => {
            dispatch({
                type: voyageType.GET_VOYAGE,
                voyageData: response.data.result,
                voyageRequestData: voyageRequestOrigin, //save it when you want to back page
                voyageFilter: response.data.result
            });
            if ((DeparturePort !== "" && DeparturePort !== null) || (ArrivalPort !== "" && ArrivalPort !== null)) {
                FilterTable(DeparturePort, ArrivalPort, response.data.result, getVoyageState, setVoyageState)
            } else {
                if (Object.keys(response.data.result).length < 1) {
                    setVoyageState({ ...getVoyageState, waitForRequest: false, voyageNotFound: true, notSearched: false, noConnect: false }) //it is not important here
                } else {
                    setVoyageState({ ...getVoyageState, waitForRequest: false, voyageNotFound: false, notSearched: false, noConnect: false }) //it is not important here
                }
            }

        })
        .catch(error => {
            setVoyageState({ ...getVoyageState, waitForRequest: false, voyageNotFound: false, notSearched: false, noConnect: true })
            dispatch({
                type: voyageType.CLEAR_VOYAGE,

            })
        })
        .finally(() => {
        });
}
export const getPorts = () => dispatch => {
    const endPoint = `${ApiUrl}/port/getPorts`;
    httpClient.post(endPoint)
        .then((response) => {
            const voyagePorts = response.data.result
            dispatch({
                type: voyageType.GET_PORTS,
                voyagePorts: voyagePorts
            })
        })
        .catch((error) => {

        })

}
export const clearVoyage = () => dispatch => {
    dispatch({
        type: voyageType.CLEAR_VOYAGE
    })
}
export const filterVoyage = (pol, pod, voyageData, getVoyageState, setVoyageState) => dispatch => {
    if (voyageData === null) return;
    if (!pol && !pod) {
        dispatch({
            type: voyageType.FILTER_VOYAGE,
            voyageFilterData: voyageData,
            filteredPorts: {
                ArrivalPort: null,
                DeparturePort: null
            }
        })
        setVoyageState({ ...getVoyageState, waitForRequest: false, voyageNotFound: false, notSearched: false, noConnect: false }) //it is not important here
        return
    }
    let voyageFilterData = []
    const voyageDataArray = Object.values(voyageData)
    if (pol && pod) {
        voyageFilterData = voyageDataArray.filter((V) => V.unLoadingPort === pod && V.loadingPort === pol)
        dispatch({
            type: voyageType.FILTER_VOYAGE,
            voyageFilterData: voyageDataArray.filter((V) => V.unLoadingPort === pod && V.loadingPort === pol),
            filteredPorts: {
                ArrivalPort: pod,
                DeparturePort: pol
            }
        })
    } else if (pol && !pod) {
        voyageFilterData = voyageDataArray.filter((V) => V.loadingPort === pol)
        dispatch({
            type: voyageType.FILTER_VOYAGE,
            voyageFilterData: voyageDataArray.filter((V) => V.loadingPort === pol),
            filteredPorts: {
                ArrivalPort: null,
                DeparturePort: pol
            }
        })
    } else if (!pol && pod) {
        voyageFilterData = voyageDataArray.filter((V) => V.unLoadingPort === pod)
        dispatch({
            type: voyageType.FILTER_VOYAGE,
            voyageFilterData: voyageDataArray.filter((V) => V.unLoadingPort === pod),
            filteredPorts: {
                ArrivalPort: pod,
                DeparturePort: null
            }
        })
    }
    if (voyageFilterData.length > 0) {
        setVoyageState({ ...getVoyageState, waitForRequest: false, voyageNotFound: false, notSearched: false, noConnect: false }) //it is not important here
    } else {
        setVoyageState({ ...getVoyageState, waitForRequest: false, voyageNotFound: true, notSearched: false, noConnect: false }) //it is not important here
    }

}
export const setVoyageDate = (StartDate, EndDate) => dispatch => {
    dispatch({
        type: voyageType.SET_VOYAGEDATE,
        StartDate: StartDate,
        EndDate: EndDate
    })
}
