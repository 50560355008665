import React from 'react'
import { Delete, Unlink, Download, Link } from '@dfds-ui/icons'
import JustHamburger from '../../CommonUI/JustHamburger/JustHamburger'

export const TsnTableSchema = {
    isRowValid: "isStatusInvalid",

    ColumnDesign: [
        {
            dataId: "provider",
            text: "Provider",
            infoText: "TSN Provider",
        },
        {
            dataId: "status",
            text: "Status",
        },
        {
            dataId: "tsn",
            text: "TSN",
        },
        {
            dataId: "frontPlate",
            text: "VRN"
        },
        {
            dataId: "unitNumber",
            text: "Unit Number"
        },
        {
            dataId: "tsnGroup",
            text: "Group Number"
        },
        {
            dataId: "lrn",
            text: "LRN"
        },

        {
            dataId: "mrn",
            text: "MRN"
        },
        {
            dataId: "declarationDateEpoch",
            text: "Declaration Date"
        },

        {
            dataId: "totalNumberOfPack",
            text: "Package"
        },

        {
            dataId: "totalGrossMass",
            text: "Weight",

        },
        {
            dataId: "actionButton",
            text: ""
        }

    ],
    DataDesign: [
        {
            dataId: "provider",
        },
        {
            dataId: "statusName",
        },
        {
            dataId: "tsn",
        },
        {
            dataId: "vehiclePlate",
        },
        {
            dataId: "unitNumber",
        },
        {
            dataId: "tsnGroup",
        },
        {
            dataId: "lrn",
        },
        {
            dataId: "mrn",
        },
        {
            dataId: "declarationDateEpoch",
            type: "DATETIME"
        },
        {
            dataId: "totalNumberOfPack",
            type: "number",
            digit: 0,
            // calculate: true

        },
        {
            dataId: "totalGrossMass",
            type: "number",
            // digit: 3,
            // calculate: true,
        },
        {
            dataId: "actionButton",
            showData: false,
            multipleControl: true,
            controlIds: [
                "statusName"
            ],
            controlResults: {
                forAction: (controls, onClick, Row, Permission) => DeleteButtonControl(controls, onClick, Row, Permission),
            },
        }

    ]

}
const DeleteButtonControl = (controls, onClick, Row, Permission) => {
    const checkForDelete = controls[0] === "DuplicateError" || controls[0] === "HaveErrorsByCustom" || controls[0] === "RejectedByAgent";
    const checkForUnmatch = controls[0] === "Matched"
    let JustContextFunctions = [
        {
            Text: "Delete",
            Icon: <Delete />,
            Active: checkForDelete,
            onClick: () => onClick(Row, "delete"),
        }, {
            Text: "Unmatch",
            Icon: <Unlink />,
            Active: checkForUnmatch,
            onClick: () => onClick(Row, "unmatch"),
        }, {
            Text: "Download Details",
            Icon: <Download />,
            Active: true,
            onClick: () => onClick(Row, "getDetails")
        }
    ]
    if (Permission && Permission.toLowerCase() === "admin" || Permission.toLowerCase() === "internaluser") {
        // JustContextFunctions.splice(2, 0, {
        //     Text: "Set to Matched",
        //     Icon: <Link />,
        //     Active: !checkForUnmatch,
        //     onClick: () => onClick(Row, "setMatched")
        // })
        JustContextFunctions.push({
            Text: "Set to Matched",
            Icon: <Link />,
            Active: !checkForUnmatch,
            onClick: () => onClick(Row, "setMatched")
        })
    }

    return <JustHamburger DialogOptions={JustContextFunctions} DialogPosition="Left" />
    // if (controls[0] === "DuplicateError" || controls[0] === "HaveErrorsByCustom") {

    //     return <DeleteButton className="DeleteRowButton" ButtonLabel="DELETE" onClick={() => onClick(Row, "delete")} />
    // } else {
    //     return <DeleteButton className="DeleteRowButton" ButtonLabel="UNMATCH" onClick={() => onClick(Row, "unmatch")} />;
    // }
}