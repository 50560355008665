//FaClipboardList
export const DeclarationHistoryStatusSchema = {
    ColumnDesign: [
        {
            dataId: "updateDateEpoch",
            text: "Status Date"
        },
        {
            dataId: "statusName",
            text: "Status",
        },
    ],
    DataDesign: [
        {
            dataId: "updateDateEpoch",
            type: "DATETIMEUTC"
        },
        {
            dataId: "statusName",
        }
    ]

}