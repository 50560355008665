import React from 'react';
import login from '../login';
import { Router, Route, Switch } from 'react-router-dom';
import history from '../../core/history'
import PrivateRoute from './PrivateRoute.js';
import HomePage from '../../component/HomePage/HomePage'
import voyage from '../voyage'
import declaration from '../declaration'
import adminpanel from '../adminpanel'
import booking from '../booking'
import NoAuth from '../../component/NoAuth/NoAuth';
import resetPassword from '../resetpassword';
import sd from '../sd';
const ENSRoutes = () => {
    const authRoles = "internaluser,Admin";
    return (
        <>
            <Router history={history}>
                <Switch>
                    <PrivateRoute exact path='/' component={HomePage} auth={authRoles} />
                    <PrivateRoute exact path='/booking' component={booking} auth={authRoles} />
                    <PrivateRoute exact path='/voyages' component={voyage} auth={authRoles} />
                    <PrivateRoute exact path='/declarations' component={declaration} auth={authRoles} />
                    <PrivateRoute exact path='/adminpanel' component={adminpanel} auth={"Admin"} />

                    <PrivateRoute exact path='/noAuthorization' component={NoAuth} />
                    <Route path='/password-reset' component={resetPassword} />
                    <Route path='/sd' component={sd} />
                    <Route path='/login' component={login} />
                    <Route component={HomePage} />
                </Switch>
            </Router>
        </>
    )
}

export default ENSRoutes;