const declarationTypes = Object.freeze({
    GETTING_DECLARATIONS: Symbol('GETTING_DECLARATIONS'),
    GET_DECLARATIONS: Symbol('GET_DECLARATIONS'),
    DECLARATION_ERROR: Symbol('DECLARATION_ERROR'),
    SET_DECLARATION_DATE: Symbol('SET_DECLARATION_DATE'),
    SET_DECLARATION_TSN: Symbol('SET_DECLARATION_TSN'),
    SET_DECLARATION_UNITNUMBER: Symbol('SET_DECLARATION_UNITNUMBER'),
    SET_DECLARATION_RELEASENUMBER: Symbol('SET_DECLARATION_RELEASENUMBER'),
    DECLARATIONS_NOT_FOUND: Symbol('DECLARATIONS_NOT_FOUND'),
    SET_SELECTED_ROW: Symbol('SET_SELECTED_ROW'),
    CLEAR_DECLARATION_PAGE: Symbol('CLEAR_DECLARATION_PAGE'),
});

export default declarationTypes;