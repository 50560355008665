import React, { useState, useEffect } from 'react';
import SpinDiv from '../../CommonUI/Spindiv/Spindiv'
import NoTsnHistory from '../../CommonUI/TableError/TableError';
import { TsnTableSchema } from './TsnHistoryTableSchema';
import PerfectTable from '../../CommonUI/Table/PerfectTable';
import { AlertWarning } from '@dfds-ui/icons';
import NetworkError from '../../CommonUI/TableError/TableError';
// import { connect } from 'react-redux';
import { Modal } from '@dfds-ui/modal'
import httpClient from "../../../core/httpclient";
import ApiUrl from '../../../core/url'
import './TsnHistoryTable.scss';
const TsnHistoryTable = (props) => {
    const endPoint = `${ApiUrl}/declaration/getdeclarationhistory`;
    const { onClose, Tsn } = props;
    const [HistoryData, setHistoryData] = useState(null)
    const [AnimationControl, setAnimCont] = useState({
        TsnNotFound: false,
        NoConnection: false,
        WaitForRequest: true,
    })
    useEffect(() => {
        getTsnHistory();
    }, [Tsn])
    const getTsnHistory = () => {
        if (Tsn === null) return (<h3>NO TSN</h3>);
        httpClient.post(endPoint, JSON.stringify(Tsn)).then(
            response => {
                if (response.data.result !== null) {
                    setHistoryData(response.data.result)
                    setAnimCont({ ...AnimationControl, WaitForRequest: false })
                } else {
                    setHistoryData(null)
                    setAnimCont({ ...AnimationControl, WaitForRequest: false, TsnNotFound: true })
                }
            }
        ).catch(error => {
            setHistoryData(null);
            setAnimCont({ ...AnimationControl, NoConnection: true, WaitForRequest: false })
        })
    }
    const clearAnimation = () => {
        setTimeout(()=>{
            setAnimCont({ TsnNotFound: false, NoConnection: false, WaitForRequest: true })
        },100)
    }
    const clearData = () => {
        setHistoryData(null)
    }
    const NoConnectionIco = <AlertWarning className="searchLogo" width="50px" height="50px" />
    const rowDivs = [
        {
            show: AnimationControl.TsnNotFound,
            component: <NoTsnHistory ShowLogo={true} Message={"No Tsn history found."} />
        },
        {
            show: AnimationControl.NoConnection,
            component: <NetworkError Logo={NoConnectionIco} ShowLogo={true} Message={"No connection."} />
        },
        {
            show: AnimationControl.WaitForRequest,
            component: <SpinDiv waitForRequest={AnimationControl.WaitForRequest} ShowLogo={false} />
        },
    ]

    return (
        <Modal className="TsnHistoryModal" isOpen={Tsn !== null} heading={<span className="tsnTitleModal">{Tsn}</span>}  onRequestClose={() => { onClose(); clearAnimation(); clearData(); }}>
            <div className="gridContainer">
                <PerfectTable
                    className="TsnHistoryTable"
                    DataArray={HistoryData}
                    TableSchema={TsnTableSchema}
                    RowRenderComps={rowDivs}
                />
            </div>
        </Modal>
    );
}
export default TsnHistoryTable;