import React from 'react'
import PropTypes from 'prop-types';
import './JustNavMenu.scss'

const JustNavMenu = (props) => {
    const { NavMenuText, NavActive, onClick } = props
    return (
        <div onClick={() => onClick()} className={"JustNavMenu-Container" + (NavActive ? "" : " NotActive-JustNavMenu")}>
            <span id="JustNavMenu-Text">
                {NavMenuText}
            </span>
        </div>
    )
}
JustNavMenu.propTypes = {
    NavMenuText: PropTypes.string,
    NavActive: PropTypes.bool,
    onClick: PropTypes.func,
};
JustNavMenu.defaultProps = {
    NavMenuText: '',
    NavActive: false,
    onClick: () => { }

};
export default JustNavMenu