import { parse } from "querystring";
import {voyagePageLanguage} from '../assets/language/language'
const language = "en"
export const timeRangeValid = (departureTime, arrivalTime) => {

    if (departureTime === null || departureTime.trim() === "" || arrivalTime === null || arrivalTime.trim() === "") {
        return { valid: false, errorMessage: "Please enter the valid date." }
    }

    // To set two dates to two variables 
    var date1 = new Date(departureTime);
    var date2 = new Date(arrivalTime);

    // To calculate the time difference of two dates 
    var Difference_In_Time = date2.getTime() - date1.getTime();

    // To calculate the no. of days between two dates 
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    if (Difference_In_Days<0) {
        return { valid: false, errorMessage: voyagePageLanguage[language].VoyageValidationError }
    }
    if (Difference_In_Days >= 30) {
        return { valid: false, errorMessage: voyagePageLanguage[language].VoyageTimeValidationError }
    }
    return { valid: true }
    
}