import React, { useState } from 'react'
import PropTypes from 'prop-types';
import './JustList.scss'
import { Modal } from '@dfds-ui/modal';

const JustList = (props) => {
    const { justList, justListMoreCount } = props
    const [ModalVisibility, setModalVisibility] = useState(false);
    return (
        ModalVisibility ?
            <Modal isOpen={true} heading="Unit Number List" onRequestClose={() => setModalVisibility(false)} shouldCloseOnEsc={true}>
                {
                    justList.map((listElement, index) => {
                        return <span className="ListElement-JustList in-modal-list" id={listElement}>
                            {listElement}
                        </span>
                    }

                    )}
            </Modal>
            :
            justList.map((listElement, index) => {
                if (justListMoreCount > index) {
                    return <span className="ListElement-JustList" id={listElement}>
                        {listElement}
                    </span>
                } else if (justListMoreCount == index) {
                    return <span onClick={() => setModalVisibility(true)} className="ListElement-ShowMoreButton">
                        {"Show More " + "+" + (justList.length - justListMoreCount)}
                    </span>
                }
            }
            )
    )
}
JustList.propTypes = {
    justList: PropTypes.array,
    justListMoreCount: PropTypes.number,


};
JustList.defaultProps = {
    justList: [],
    justListMoreCount: 5,
};
export default JustList