const XMSErrorHandler = (error) => {
    if (error.response === undefined) {
        return {
            ErrorCode: 500,
            ErrorDescription: error.message
        }
    } else {
        const errorResponse = error.response;
        const errorData = errorResponse ? errorResponse.data : null;
        const errors = errorData ? errorData.errors && errorData.errors[0] : null;
        const errorCode = errors ? errors.code : "";
        const errorDesc = errors ? errors.description : "Service error.";
        return {
            ErrorCode: errorCode,
            ErrorDescription: errorDesc
        }
    }
}
export default XMSErrorHandler;