import React from 'react'

export const JustOptionManager = ({ Options, DisableValue, DefaultSelected }) => {
    return (
        <>
            {
                Options && Options.length > 0 &&
                Options.map((Option, index) => {
                    return <option value={Option.Value} selected={Option.Value === DefaultSelected} disabled={DisableValue === Option.Value}>{Option.Text}</option>
                })
            }
        </>
    )
}
