export const userLoginError = (errorStatus,User,setState) => {
    switch (errorStatus) {
        case 500:
            setState({
                ...User,
                ErrorMessage: "Incorrect username or password.",
                ErrorCode: errorStatus,
                userPass: "",
                userName : ""
            });
            break;
        case 0:
            setState({
                ...User,
                ErrorMessage: "I couldn't connect to server.",
                ErrorCode: errorStatus
            });
            break;
        default:
            setState({
                ...User,
                ErrorMessage: "Error Code : " + errorStatus,
                ErrorCode: errorStatus,
                userPass: "",
                userName : ""
            });
        break;
    }
}

