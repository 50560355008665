export const VoyageTableSchema = {
    ColumnDesign: [
        {
            dataId: "voyageNumber",
            text: "Voyage Number",
            filter: "text",
            filterLabelText: "Voyage Number",
            filterPlaceHolder: "Filter for Voyage Number",
        },
        {
            dataId: "vessel",
            text: "Vessel Name",
            filter: "text",
            filterLabelText: "Vessel Name",
            filterPlaceHolder: "Filter for Vessel Name",
            filterCaseSensitive: false
        },
        {
            dataId: "route",
            text: "Route",
        },
        {
            dataId: "loadingPort",
            text: "POL",
            infoText: "Port of Loading",
        },
        {
            dataId: "unLoadingPort",
            text: "POD",
            infoText: "Port of Departure",
        },
        {
            dataId: "departureDateTimeEpoch",
            text: "Departure Date"
        },
        {
            dataId: "expectedArrivalDateTimeEpoch",
            text: "ETA",
            infoText: "Estimated Time of Arrival",
        },
    ],
    DataDesign: [
        {
            dataId: "voyageNumber",
            // icon : "ship"
        },
        {
            dataId: "vessel",
        },
        {
            dataId: "route",
        },
        {
            dataId: "loadingPort",
        },
        {
            dataId: "unLoadingPort",
        },
        {
            dataId: "departureDateTimeEpoch",
            type: "datetime",
        },
        {
            dataId: "expectedArrivalDateTimeEpoch",
            type: "datetime",
        },
    ]
}
export const DummyVoyageData = [
    {
        voyageNumber: 123456,
        vessel: "Furkan",
        loadingPort: "PEN",
        unLoadingPort: "TRI",
        departureDateTimeEpoch: 1534326784,
        expectedArrivalDateTimeEpoch: 1234567889
    }
]