import React from 'react'
import './DeclarationTable.scss'
import { connect } from 'react-redux'
import SpinDiv from '../../CommonUI/Spindiv/Spindiv'
import NoDeclarations from '../../CommonUI/TableError/TableError';
import NetworkError from '../../CommonUI/TableError/TableError';
import PerfectTable from '../../CommonUI/Table/PerfectTable';
import { AlertWarning } from '@dfds-ui/icons';
import { DeclarationTableSchema, DummyDeclaration } from './DeclarationTableSchema';
import { setSelectedRow } from '../../../store/action/declarationAction';

const DeclarationTable = (props) => {
    const { Declarations, NoConnection, DeclarationsNotFound, WaitForDeclarations, setSelectedRow, SearchDeclarations } = props
    const NoConnectionIco = <AlertWarning className="searchLogo" width="50px" height="50px" />
    const rowDivs = [
        {
            show: DeclarationsNotFound,
            component: <NoDeclarations ShowLogo={true} Message={"Declarations Not Found"} />
        },
        {
            show: NoConnection,
            component: <NetworkError Logo={NoConnectionIco} ShowLogo={true} Message={"No Connection"} />
        },
        {
            show: WaitForDeclarations,
            component: <SpinDiv waitForRequest={WaitForDeclarations} ShowLogo={false} size={50} />
        },
        {
            show: SearchDeclarations,
            component: <NoDeclarations ShowLogo={true} Message={"Search Declarations"} />
        },
    ]
    const _onHistoryClick = (e, row) => {
        setSelectedRow(row)
    }
    return (
        <div className="DeclarationTable-Container">
            <PerfectTable
                DataArray={Declarations}
                TableSchema={DeclarationTableSchema}
                onControlBtnClick={(e, row) => _onHistoryClick(e, row)}
                // OnRowClick={DummyDeclaration}
                RowRenderComps={rowDivs}
            />
        </div>
    )
}
const mapStateToProps = state => {
    return {
        Declarations: state.declaration.Declarations,
        NoConnection: state.declaration.NoConnection,
        DeclarationsNotFound: state.declaration.DeclarationsNotFound,
        WaitForDeclarations: state.declaration.WaitForDeclarations,
        SearchDeclarations: state.declaration.SearchDeclarations,
    }
}
export default connect(mapStateToProps, { setSelectedRow })(DeclarationTable);