import declarationTypes from "../type/declaration";
import httpClient from "../../core/httpclient";
import ApiUrl from '../../core/url'
import ProjectConfig from "../../config/config";
import PropTypes from 'prop-types';
import XMSErrorHandler from '../../core/xmsErrorHandler'
import moment from 'moment';

export const getDeclarations = (DeclarationDateStart, DeclarationDateEnd, UnitNumber, ReleaseNumber, Tsn) => dispatch => {
    if (!(DeclarationDateStart || DeclarationDateEnd || UnitNumber || ReleaseNumber || Tsn)) {
        dispatch({
            type: declarationTypes.DECLARATION_ERROR,
            ErrorTitle: "Validation Error",
            ErrorCode: 405,
            ErrorDescription: "Fill at least one filter value.",
            ValidationError: true
        })
        return
    }
    const endPoint = `${ProjectConfig.apiUrl}/declaration/getfiltereddeclarations`
    const DisplayDeclarationFilter = {
        DeclarationDateStart: moment(DeclarationDateStart).subtract(moment.unix(DeclarationDateStart).utcOffset()).unix(),
        // DeclarationDateEnd: DeclarationDateEnd.split('-').join(''),
        UnitNumber: UnitNumber,
        ReleaseNumber: ReleaseNumber,
        Tsn: Tsn,
    }
    dispatch({
        type: declarationTypes.GETTING_DECLARATIONS,
    })
    httpClient.post(endPoint, JSON.stringify(DisplayDeclarationFilter))
        .then((response) => {
            const Declarations = response.data.result
            if (Declarations && Declarations.length > 0) {
                dispatch({
                    type: declarationTypes.GET_DECLARATIONS,
                    Declarations: Declarations
                })
            } else {
                dispatch({
                    type: declarationTypes.DECLARATIONS_NOT_FOUND
                })
            }
        })
        .catch((error) => {
            const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
            dispatch({
                type: declarationTypes.DECLARATION_ERROR,
                ErrorTitle: "Getting declaration error",
                ErrorCode: 500,
                ErrorDescription: ErrorDescription
            })
        })

}
getDeclarations.propTypes = {
    DeclarationDateStart: PropTypes.number,
    DeclarationDateEnd: PropTypes.number,
    UnitNumber: PropTypes.string,
    ReleaseNumber: PropTypes.string,
    Tsn: PropTypes.string,
};
export const setDeclarationDate = (StartDate, EndDate) => dispatch => {
    dispatch({
        type: declarationTypes.SET_DECLARATION_DATE,
        StartDate: StartDate,
        EndDate: EndDate,
    })
}
export const setSelectedRow = (selectedRow) => dispatch => {
    dispatch({
        type: declarationTypes.SET_SELECTED_ROW,
        SelectedRow: selectedRow
    })
}
export const setDeclarationTsn = (Tsn) => dispatch => {
    dispatch({
        type: declarationTypes.SET_DECLARATION_TSN,
        Tsn: Tsn,
    })
}
export const setDeclarationUnitNumber = (UnitNumber) => dispatch => {
    dispatch({
        type: declarationTypes.SET_DECLARATION_UNITNUMBER,
        UnitNumber: UnitNumber
    })
}
export const setDeclarationReleaseNumber = (ReleaseNumber) => dispatch => {
    dispatch({
        type: declarationTypes.SET_DECLARATION_RELEASENUMBER,
        ReleaseNumber: ReleaseNumber
    })
}
export const clearPage = () => dispatch => {
    dispatch({
        type: declarationTypes.CLEAR_DECLARATION_PAGE
    })
}
export const clearError = () => dispatch => {
    dispatch({
        type: declarationTypes.DECLARATION_ERROR,
        ErrorTitle: "",
        ErrorCode: -1,
        ErrorDescription: ""
    })
}



