import React from 'react'
import JustHeader from './JustHeader/JustHeader'
import JustRow from './JustRow/JustRow'
import './JustTable.scss'
import PropTypes from 'prop-types';
import TableContext from './JustTableContext'
import { ArrowLock, ArrowToLeft } from '../Helper/ArrowLock'
import JustWait from '../Helper/Component/JustAnimations/JustWait/JustWait'
import JustNotFound from '../Helper/Component/JustAnimations/JustNotFound/JustNotFound'
import JustError from '../Helper/Component/JustAnimations/JustError/JustError'
import { setDataFromParent } from '../Helper/ObjectTree';
import { JustSplit } from '../Helper/JustSplit';
import { arraysEqual } from '../Helper/JustArrayEqual';

export default class JustTable extends React.Component {
    constructor(props) {
        super(props)
        this.state = { dataHistory: [], dataSelected: [], allSelect: false, DataArrayClone: [] }
        ArrowLock()
        // ArrowToLeft()
    }

    onCellDataChange = (rowIndex, rowId, row, columnId, dataList, dataType, ParentIds, rowUpdate, setRow) => {
        let initialize = true
        // 
        if (this.state.dataHistory.length > 0) {
            this.state.dataHistory.forEach((DataHistory, rkey) => {
                if (DataHistory.rowId === rowId) {
                    initialize = false;
                    DataHistory.cells.forEach((Cell, ckey) => {
                        const firstData = (dataList[0] === null || dataList[0] === undefined) ? "" : dataList[0]
                        const lastData = (dataType && dataType.toUpperCase() === "ARRAY") ? JustSplit(dataList[dataList.length - 1], ';') : dataList[dataList.length - 1]
                        dataList[dataList.length - 1] = lastData
                        if (Cell.columnId === columnId) {

                            if (firstData === lastData || (arraysEqual(firstData, lastData))) {
                                if (this.state.dataHistory[rkey].cells.length === 1) {
                                    if (this.state.dataHistory.length === 1) {

                                        setDataFromParent(ParentIds, Cell.columnId, this.state.dataHistory[rkey].row, dataList[0])
                                        // this.state.dataHistory[rkey].row[Cell.columnId] = dataList[0]
                                        rowUpdate(false, this.state.dataHistory[rkey].row, () => {

                                            this.props.JustDataHistory([],
                                                {
                                                    Row: this.state.dataHistory[rkey].row, RowIndex: rowIndex, RowId: rowId,
                                                    ColumnId: columnId, Data: firstData, setRow: setRow,
                                                    dataList: dataList
                                                })

                                            this.setState({ ...this.state, dataHistory: [] })
                                        })
                                        //


                                        // 
                                        return true
                                    } else {
                                        setDataFromParent(ParentIds, Cell.columnId, this.state.dataHistory[rkey].row, dataList[0])
                                        rowUpdate(false, this.state.dataHistory[rkey].row, () => {
                                            this.props.JustDataHistory(this.state.dataHistory,
                                                {
                                                    Row: this.state.dataHistory[rkey].row, RowIndex: rowIndex, RowId: rowId,
                                                    ColumnId: columnId, Data: firstData, setRow: setRow, dataList: dataList
                                                })
                                            delete this.state.dataHistory[rkey]
                                            // this.state.dataHistory[rkey].row[Cell.columnId] = dataList[0]
                                            this.state.dataHistory.splice(rkey, 1)
                                        })

                                        return true
                                    }
                                } else {
                                    setDataFromParent(ParentIds, Cell.columnId, this.state.dataHistory[rkey].row, dataList[0])
                                    rowUpdate(true, this.state.dataHistory[rkey].row)
                                    this.props.JustDataHistory(this.state.dataHistory,
                                        {
                                            Row: this.state.dataHistory[rkey].row, RowIndex: rowIndex, RowId: rowId, ColumnId: columnId,
                                            Data: firstData, setRow: setRow, dataList: dataList
                                        })
                                    delete this.state.dataHistory[rkey].cells[ckey]
                                    // this.state.dataHistory[rkey].row[Cell.columnId] = dataList[0]
                                    // 

                                    this.state.dataHistory[rkey].cells.splice(ckey, 1)

                                    return true
                                }

                            } else {
                                this.state.dataHistory[rkey].cells[ckey] = { columnId: Cell.columnId, dataList: dataList }
                                // this.state.dataHistory[rkey].row[Cell.columnId] = dataList[dataList.length - 1]
                                setDataFromParent(ParentIds, Cell.columnId, this.state.dataHistory[rkey].row, dataList[dataList.length - 1])
                                // 

                                rowUpdate(true, this.state.dataHistory[rkey].row)

                                this.props.JustDataHistory(this.state.dataHistory,
                                    {
                                        Row: this.state.dataHistory[rkey].row, RowIndex: rowIndex, RowId: rowId,
                                        ColumnId: columnId, Data: dataList[dataList.length - 1], setRow: setRow, dataList: dataList
                                    })
                                return true
                            }
                        }
                        if (ckey === (DataHistory.cells.length - 1)) {

                            this.state.dataHistory[rkey].cells.push({ columnId: columnId, dataList: dataList })
                            // this.state.dataHistory[rkey].row[columnId] = dataList[dataList.length - 1]
                            setDataFromParent(ParentIds, columnId, this.state.dataHistory[rkey].row, dataList[dataList.length - 1])
                            rowUpdate(true, this.state.dataHistory[rkey].row)

                            this.props.JustDataHistory(this.state.dataHistory, {
                                Row: this.state.dataHistory[rkey].row, RowIndex: rowIndex, RowId: rowId,
                                ColumnId: columnId, Data: dataList[dataList.length - 1], setRow: setRow, dataList: dataList
                            })
                            return true
                        }
                    })
                    // if (!updated) {

                    // }
                }
            })
        }
        if (initialize) {
            // 
            const firstData = dataList[0]
            const lastData = (dataType && dataType.toUpperCase() === "ARRAY") ? JustSplit(dataList[dataList.length - 1], ';') : dataList[dataList.length - 1]
            dataList[dataList.length - 1] = lastData
            // if (firstData === lastData) {
            //     return
            // }
            setDataFromParent(ParentIds, columnId, row, lastData)
            rowUpdate(true, row)
            this.setState({
                dataHistory:
                    [
                        ...this.state.dataHistory,
                        {
                            rowIndex: rowIndex,
                            rowId: rowId,
                            row: row,
                            cells: [
                                { columnId, dataList }
                            ]
                        }
                    ]
            }, () => {
                // this.state.dataHistory[this.state.dataHistory.length - 1].row[columnId] = dataList[dataList.length - 1]

                this.props.JustDataHistory(this.state.dataHistory, {
                    Row: row, RowIndex: rowIndex, RowId: rowId, ColumnId: columnId,
                    Data: lastData, setRow: setRow, dataList: dataList
                })

            })
        }

    }
    
    selectAll = (Selected) => {
        const bindOutSide = () => {
            if (this.props.JustSelectedRow && typeof this.props.JustSelectedRow === "function") {
                this.props.JustSelectedRow(this.state.dataSelected)
            }
        }
        const checkBoxes = document.getElementsByClassName('JustTable-RowSelect-CheckBox')
        if (Selected) {
            let newArray = JSON.parse(JSON.stringify(this.props.JustData));
            const TableSchema = this.props.JustSchema;
            if (this.state.dataHistory.length > 0) {
                this.state.dataHistory.map((bindChangedRow) => {
                    newArray.forEach((oldRow, index) => {
                        if (bindChangedRow.rowId === oldRow[TableSchema.rowId]) {
                            newArray[index] = bindChangedRow.row
                            return
                        }
                    })
                })
            }
            this.setState({ ...this.state, dataSelected: newArray, allSelect: true }, () => bindOutSide())

            checkBoxes.forEach((checkBox) => checkBox.checked = true)
        } else {
            this.setState({ ...this.state, dataSelected: [], allSelect: false }, () => bindOutSide())
            checkBoxes.forEach((checkBox) => checkBox.checked = false)
        }
    }
    onSelected = (Row, Selected, tdRef) => {
        const parentRef = tdRef && tdRef.current && tdRef.current.parentNode
        const tdRefCurrent = tdRef && tdRef.current
        const bindOutSide = () => {
            if (this.props.JustSelectedRow && typeof this.props.JustSelectedRow === "function") {
                this.props.JustSelectedRow(this.state.dataSelected, Row)
            }
        }
        if (Selected) {
            const newArray = [...this.state.dataSelected, Row]
            const rowsLength = this.props.JustData.length
            if (newArray.length === rowsLength) {
                this.setState({ ...this.state, dataSelected: newArray, allSelect: true }, () => bindOutSide())
            } else {

                this.setState({ ...this.state, dataSelected: newArray }, () => bindOutSide())
            }
            if (parentRef) {
                // parentRef.style.backgroundColor = "rgb(0,0,0,0.5)"
                // tdRefCurrent.style.backgroundColor = "rgb(0,0,0,0.5)"
                // parentRef.style.borderBottom = "1px solid rgba(0, 43, 69, 0.5)"
                // parentRef.style.borderRight = "1px solid rgba(0, 43, 69, 0.5)"
                // parentRef.style.borderLeft = "1px solid rgba(0, 43, 69, 0.5)"
                // parentRef.style.borderTop = "1px solid rgba(0, 43, 69, 0.5)"
            }

        } else {
            const selectedArray = this.state.dataSelected
            const rowId = this.props.JustSchema.rowId
            const newArray = selectedArray.filter(item => item[rowId] !== Row[rowId])

            this.setState({ ...this.state, dataSelected: newArray, allSelect: false }, () => bindOutSide())
            if (parentRef) {
                // parentRef.style.backgroundColor = ""
                // tdRefCurrent.style.backgroundColor = ""
                // parentRef.style.borderBottom = "1px solid rgba(0, 0, 0, 0.15)"
                // parentRef.style.borderRight = "1px solid rgba(0, 0, 0, 0)"
                // parentRef.style.borderLeft = "1px solid rgba(0, 0, 0, 0)"
                // parentRef.style.borderTop = "1px solid rgba(0, 0, 0, 0)"
            }
        }

    }
    afterSort = (sortedArray) => {
        if (this.props.JustSortArray && typeof this.props.JustSortArray === "function") {
            this.props.JustSortArray(sortedArray)
            // this.JustTableBusy(false)
        }
    }
    UNSAFE_componentWillMount() {

    }
    JustTableBusy = (TableBusy) => {
        if (this.props.JustTableBusy && typeof this.props.JustTableBusy === "function")
            this.props.JustTableBusy(TableBusy)
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.JustAnimations !== this.props.JustAnimations) {
            if (nextProps.JustAnimations.WaitForRequest !== this.props.JustAnimations.WaitForRequest ||
                nextProps.JustAnimations.NoConnection !== this.props.JustAnimations.NoConnection) {

                this.setState({ dataSelected: [], dataHistory: [], allSelect: false })
                return true
            }
        }
        if (nextProps.JustAnimationMessages !== this.props.JustAnimationMessages) {
            return true
        }
        if (nextState.dataSelected !== this.state.dataSelected) {
            return true
        }
        if (nextProps.JustData !== this.props.JustData) {
            this.setState({ dataSelected: [], dataHistory: [] })
            return true;
        } else if (nextState.dataHistory !== this.state.dataHistory) {
            // 
            return false
        }
        if (nextProps.JustSchema !== this.props.JustSchema) {
            return true
        }
        if (nextProps.JustUpdate && this.props.JustUpdate) {
            if (nextProps.JustUpdate.rowId !== this.props.JustUpdate.rowId ||
                nextProps.JustUpdate.columnId !== this.props.JustUpdate.columnId ||
                nextProps.JustUpdate.updateData !== this.props.JustUpdate.updateData) {
                this.externalUpdate(nextProps.JustUpdate.rowId, nextProps.JustUpdate.columnId, nextProps.JustUpdate.updateData)
                return false //şimdilik ileride lazım olabilir
            }
        }
        // if (nextProps.JustForceRender !== this.props.JustForceRender) {
        //     return true
        // }
        return false;
    }

    render() {
        const TableSchema = this.props.JustSchema;
        //Empty TableSchema (Noschema = Notable!)
        if (!TableSchema) return <></>
        if (Object.entries(TableSchema).length === 0) return <></>

        var columnDesigns = TableSchema.ColumnDesign;
        var dataDesign = TableSchema.DataDesign;
        const rowContextMenu = TableSchema.RowContextMenu ? TableSchema.RowContextMenu : null
        const rowSelectable = TableSchema.RowSelectable
        const DataHistory = this.state.dataHistory
        if (rowContextMenu !== null && columnDesigns[0].columnId !== "actionContext" && columnDesigns[1].columnId !== "actionContext") {
            columnDesigns.unshift({
                columnId: "actionContext",
                Text: "",
                Options: {
                    DesignLock: {
                        Default: true,
                        Width: "50px"
                    },
                }
            })
            dataDesign.unshift({
                columnId: "actionContext",
                dataType: "contextmenu"
            })
        }
        if (rowSelectable && columnDesigns[0].columnId !== "JustTable-RowSelect") {
            columnDesigns.unshift({
                columnId: "JustTable-RowSelect",
                Text: "",
                Options: {
                    DesignLock: {
                        Default: true,
                        Width: "50px"
                    },
                }
            })
            dataDesign.unshift({
                columnId: "JustTable-RowSelect",
                dataType: "JustTable-RowSelect-CheckBox"
            })
        }
        const dataArray = this.props.JustData;
        const rowId = TableSchema.rowId

        //Event Listeners
        const Events = this.props.JustEvents;
        const _onRowCellClick = Events ? (Events.onRowCellClick ? Events.onRowCellClick : () => { }) : () => { }
        const _onRowClick = Events ? (Events.onRowClick ? Events.onRowClick : () => { }) : () => { }
        const _onHeaderCellClick = Events ? (Events.onHeaderCellClick ? Events.onHeaderCellClick : () => { }) : () => { }
        const _onHeaderRowClick = Events ? (Events.onHeaderRowClick ? Events.onHeaderRowClick : () => { }) : () => { }
        //-------

        const WaitForRequest = this.props.JustAnimations ? this.props.JustAnimations.WaitForRequest : false
        // const NotFound = this.props.JustAnimations ? this.props.JustAnimations.DataNotFound : false
        const NetworkError = this.props.JustAnimations ? this.props.JustAnimations.NetworkError : false
        const CanvasWidth = this.props.JustAnimations ? this.props.JustAnimations.CanvasWidth ? this.props.JustAnimations.CanvasWidth : 1260 : 1260
        const notFoundMessage = this.props.JustAnimationMessages ? this.props.JustAnimationMessages.NotFound : "Not Found"
        const networkMessage = this.props.JustAnimationMessages ? this.props.JustAnimationMessages.NoConnection : "No Connection"

        const rowLock = TableSchema.RowLock;

        const inputEvents = this.props.JustInputEvents
        // 

        const notSearched = this.props.notSearched;
        const notSearchedText = notSearched && notSearched.Text;
        const notSearchedStatus = notSearched && notSearched.Status
        return (
            <TableContext.Provider value=
                {
                    {
                        changeData: (rowIndex, rowId, row, columnId, dataList, dataType, ParentIds, rowUpdate, setRow) =>
                            this.onCellDataChange(rowIndex, rowId, row, columnId, dataList, dataType, ParentIds, rowUpdate, setRow),
                        onRowCellClick: _onRowCellClick,
                        inputEvents: inputEvents,
                        onSelected: this.onSelected
                    }
                }>
                <div className="JustTable-Container">
                    <table className="JustTable-Table">
                        <thead className="JustTable-Table-Header">
                            <JustHeader
                                onHeaderRowClick={_onHeaderRowClick}
                                ColumnDesigns={columnDesigns}
                                onHeaderCellClick={_onHeaderCellClick}
                                onSelectall={this.selectAll}
                                Selection={this.state.allSelect}
                                SelectionPermission={dataArray && dataArray.length > 0}
                                DataArray={dataArray}
                                AfterSort={this.afterSort}
                                TableBusy={this.JustTableBusy}
                            />

                        </thead>
                        {
                            notSearchedStatus ?
                                <JustNotFound Text={notSearchedText} CanvasWidth={CanvasWidth} />
                                :
                                WaitForRequest ?
                                    <JustWait CanvasWidth={CanvasWidth} /> :
                                    NetworkError ?
                                        <JustError CanvasWidth={CanvasWidth} Text={networkMessage} />
                                        :
                                        (
                                            (dataArray && dataArray.length > 0) ?
                                                <tbody className="JustTable-Table-Rows">
                                                    {
                                                        dataArray.map((rowData, key) => {
                                                            return <JustRow
                                                                onRowClick={_onRowClick}
                                                                RowIndex={key}
                                                                RowId={rowId}
                                                                key={key}
                                                                DataDesign={dataDesign}
                                                                RowData={rowData}
                                                                ColumnDesigns={columnDesigns}
                                                                RowContextMenu={rowContextMenu}
                                                                RowContextActions={this.props.JustContextFunctions}
                                                                RowLock={rowLock}
                                                                DataHistory={DataHistory}
                                                                DataSelected={this.state.dataSelected}
                                                            />
                                                        })
                                                    }
                                                </tbody>
                                                :
                                                <JustNotFound CanvasWidth={CanvasWidth} Text={notFoundMessage} />


                                        )
                        }
                    </table>

                </div >
            </TableContext.Provider >
        )
    }
}
JustTable.propTypes = {
    JustData: PropTypes.array,
    JustSchema: PropTypes.object,
    JustDataHistory: PropTypes.func,
    JustEvents: PropTypes.object
}
JustTable.defaultProps = {
    JustData: [],
    JustSchema: {},
    JustDataHistory: () => 0,
    JustEvents: {}
}