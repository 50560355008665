import React, { useEffect } from 'react';
import history from '../../core/history'
import { clearUserStorage } from '../../core/ens_storage'
import { logOutUser } from '../../store/action/userActions'
import { connect } from 'react-redux'; 
import { ErrorModal } from '../CommonUI/Modals/ErrorModal'
import './NoAuth.css';

import {NoAuthLanguage} from '../../assets/language/language'
const language = "en"
const NoAuth = ({logOutUser}) => {
    useEffect(() => {
        clearUserStorage();
        logOutUser();
    }, [])
    const onClose = () => {
        history.push('/login')
    }
    return (
    <ErrorModal
        ErrorTitle = {NoAuthLanguage[language].NoAuthTitle}
        ErrorMessage = {NoAuthLanguage[language].NoAuthMessage}
        OnClose = {onClose}
        ButtonLabel = {NoAuthLanguage[language].NoAuthButtonLabel}
    />);
}
export default connect(null,{ logOutUser })(NoAuth);