import bookingType from '../type/booking';

const initialState = {
    bookingData: null,
    allData: null,
    WaitForUnmatchMatch: false,
    reportsData: null,
    ExcelModal: false,
    ExcelDownloadStatus: false,
    matchData : [],
    SelectedBookingIdsForDelete: [],
    WaitForDeleteBookings: false,
    ErrorHandler:{
        ErrorCode: -1,
        ErrorDescription: "",
    },
    RefreshBookingsPage: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case bookingType.GET_BOOKING:
            return {
                ...state,
                bookingData: {
                    ...action.bookingData
                },
                allData: action.allData
            }
        case bookingType.CLEAR_BOOKING:
            return {
                ...state,
                bookingData: null,
                allData: null,
                reportsData: null
            }
        case bookingType.MATCH_FILTER:
            return {
                ...state,
                matchStatus: action.matchStatus
            }
        case bookingType.WAIT_FOR_UNMATCH_MATCH:
            return {
                ...state,
                WaitForUnmatchMatch: true
            }
        case bookingType.UNMATCH_MATCHED:
            return {
                ...state,
                WaitForUnmatchMatch: false
            }
        case bookingType.GET_REPORTS:
            return {
                ...state,
                reportsData: action.reportsData
            }
        case bookingType.SET_EXCELMODAL:
            return {
                ...state,
                ExcelModal: action.ExcelModal
            }
        case bookingType.EXCEL_DOWNLOAD_STATUS:
            return {
                ...state,
                ExcelDownloadStatus: action.ExcelDownloadStatus
            }
        case bookingType.SET_SELECTED_MATCH_DECLARATION:
            return{
                ...state,
                matchData : action.matchData
            }
        case bookingType.SET_SELECTED_BOOKINGS_FOR_DELETE:
            return{
                ...state,
                SelectedBookingIdsForDelete: [...state.SelectedBookingIdsForDelete, action.SelectedBookingIdForDelete]
            }
        case bookingType.REMOVE_SELECTED_BOOKINGS_FOR_DELETE:
            return{
                ...state,
                SelectedBookingIdsForDelete: state.SelectedBookingIdsForDelete.filter((bookingId) => bookingId !== action.SelectedBookingIdForDelete)
            }
        case bookingType.RESET_SELECTED_BOOKINGS:
            return{
                ...state,
                SelectedBookingIdsForDelete: [],
            };
        case bookingType.DELETING_BOOKINGS:
            return {
                ...state,
                WaitForDeleteBookings: true,
            }
        case bookingType.DELETE_BOOKINGS:
            return {
                ...state,
                WaitForDeleteBookings: false,
                RefreshBookingsPage: true,
            }
        case bookingType.DELETE_BOOKINGS_ERROR:
            return {
                ...state,
                WaitForDeleteBookings: false,
                ErrorHandler: {
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription,
                },
            }
        case bookingType.SET_ALL_SELECTED_BOOKINGS_FOR_DELETE:
            return {
                ...state,
                SelectedBookingIdsForDelete: action.BookingIds,
            }
        default:
            return state;
    }
}