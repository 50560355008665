import adminPanelTypes from '../type/adminPanel';
import moment from 'moment';
const initialState = {
    ShowIdentityUserModal: false,

    WaitForUsers: false,
    WaitForUserRoles: false,
    WaitForSaveUser: false,
    WaitForDeleteUser: false,
    ErrorHandler: {
        ErrorCode: -1,
        ErrorDescription: "",
    },
    Users: [],
    Roles: [],
    SelectedUser: null,
    ShowDeleteModal: false,

    IdentityUsername: null,
    IdentityName: null,
    IdentitySurname: null,
    IdentityEmail: null,
    IdentityRole: null,
    IdentityStatus: null,
    IdentityCompanyCode: null,
    IdentityDepartment: null,
    IdentityPassword: null,
    RefreshUserPage: false,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case adminPanelTypes.IDENTITY_USER_MODAL_SHOW:
            return {
                ...state,
                ShowIdentityUserModal: true
            }
        case adminPanelTypes.IDENTITY_USER_MODAL_HIDE:
            return {
                ...state,
                ShowIdentityUserModal: false,
                SelectedUser: null,
                IdentityUsername: null,
                IdentityName: null,
                IdentitySurname: null,
                IdentityEmail: null,
                IdentityRole: null,
                IdentityStatus: null,
                IdentityCompanyCode: null,
                IdentityDepartment: null,
                IdentityPassword: null,
                WaitForSaveUser: false,

            }
        case adminPanelTypes.DELETE_USER_MODAL_SHOW:
            return {
                ...state,
                ShowDeleteModal: true,
                IdentityUsername: action.IdentityUsername

            }
        case adminPanelTypes.DELETE_USER_MODAL_HIDE:
            return {
                ...state,
                ShowDeleteModal: false,
                SelectedUser: action.SelectedUser,
                WaitForDeleteUser: false,
            }
        case adminPanelTypes.SET_SELECTED_USER:
            debugger
            return {
                ...state,
                SelectedUser: action.SelectedUser,
                ShowIdentityUserModal: true,
                IdentityUsername: action.SelectedUser.username,
                IdentityName: action.SelectedUser.name,
                IdentitySurname: action.SelectedUser.surname,
                IdentityEmail: action.SelectedUser.email,
                IdentityRole: action.SelectedUser.roles,
                IdentityStatus: action.SelectedUser.status,
                IdentityCompanyCode: action.SelectedUser.companyCode,
                IdentityDepartment: action.SelectedUser.department,
                IdentityPassword: action.SelectedUser.password,

            }
        case adminPanelTypes.DELETING_USER:
            return {
                ...state,
                WaitForDeleteUser: true,
            }
        case adminPanelTypes.DELETE_USER:
            return {
                ...state,
                WaitForDeleteUser: false,
                ShowDeleteModal: false,
                RefreshUserPage: true,
            }

        case adminPanelTypes.DELETE_USER_ERROR:
            return {
                ...state,
                WaitForDeleteUser: false,
                ShowDeleteModal: false,
                ErrorHandler: {
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription,
                },
            }
        case adminPanelTypes.SAVING_USER:
            return {
                ...state,
                WaitForSaveUser: true
            }
        case adminPanelTypes.SAVE_USER:
            return {
                ...state,
                WaitForSaveUser: false,
                ShowIdentityUserModal: false,
                IdentityUsername: null,
                IdentityName: null,
                IdentitySurname: null,
                IdentityEmail: null,
                IdentityRole: null,
                IdentityStatus: null,
                IdentityCompanyCode: null,
                IdentityDepartment: null,
                IdentityPassword: null,
                RefreshUserPage: true,
            }
        case adminPanelTypes.SAVE_USER_ERROR:
            return {
                ...state,
                WaitForSaveUser: false,

                ErrorHandler: {
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription,
                },
            }
        case adminPanelTypes.GETTING_USERS:
            return {
                ...state,
                WaitForUsers: true,
                RefreshUserPage: false,
            }
        case adminPanelTypes.GET_USERS:
            return {
                ...state,
                WaitForUsers: false,
                Users: action.Users
            }
        case adminPanelTypes.GET_USERS_ERROR:
            return {
                ...state,
                WaitForUsers: false,
                ErrorHandler: {
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription,
                },
            }
        case adminPanelTypes.GETTING_USER_ROLES:
            return {
                ...state,
                WaitForUserRoles: true
            }
        case adminPanelTypes.GET_USER_ROLES:
            return {
                ...state,
                WaitForUserRoles: false,
                Roles: action.Roles,
                IdentityRole: action.IdentityRole,
            }
        case adminPanelTypes.GET_USER_ROLES_ERROR:
            return {
                ...state,
                WaitForUserRoles: false,
                ErrorHandler: {
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription,
                },
            }
        case adminPanelTypes.SET_ERROR:
            return {
                ...state,
                ErrorHandler: {
                    ErrorState: "CUSTOM_ERROR",
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription
                }
            }
        case adminPanelTypes.SET_NETWORK_ERROR:
            return {
                ...state,
                NoConnection: true,
                WaitForIncomingInvoices: false,
                NotFound: false,
                ErrorHandler: {
                    ErrorState: "NETWORK_ERROR",
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription
                }
            }
        case adminPanelTypes.CLEAR_ERROR:
            return {
                ...state,
                ErrorHandler: {
                    ErrorState: "",
                    ErrorCode: -1,
                    ErrorDescription: ""
                }
            }
        case adminPanelTypes.CLEAR_NETWORK_ERROR:
            return {
                ...state,
                ErrorHandler: {
                    ErrorState: "",
                    ErrorCode: -1,
                    ErrorDescription: ""
                }
            }
        case adminPanelTypes.SET_IDENTITY_USER_USER_NAME:
            return {
                ...state,
                IdentityUsername: action.IdentityUsername,
            }
        case adminPanelTypes.SET_IDENTITY_USER_NAME:
            return {
                ...state,
                IdentityName: action.IdentityName,
            }
        case adminPanelTypes.SET_IDENTITY_USER_SURNAME:
            return {
                ...state,
                IdentitySurname: action.IdentitySurname,
            }
        case adminPanelTypes.SET_IDENTITY_USER_EMAIL:
            return {
                ...state,
                IdentityEmail: action.IdentityEmail,
            }
        case adminPanelTypes.SET_IDENTITY_USER_ROLE:
            return {
                ...state,
                IdentityRole: action.IdentityRole,

            }
        case adminPanelTypes.SET_IDENTITY_USER_STATUS:
            return {
                ...state,
                IdentityStatus: action.IdentityStatus,

            }
        case adminPanelTypes.SET_IDENTITY_USER_COMPANY_CODE:
            return {
                ...state,
                IdentityCompanyCode: action.IdentityCompanyCode,

            }
        case adminPanelTypes.SET_IDENTITY_USER_DEPARTMENT:
            return {
                ...state,
                IdentityDepartment: action.IdentityDepartment,

            }
        case adminPanelTypes.SET_IDENTITY_USER_PASSWORD:
            return {
                ...state,
                IdentityPassword: action.IdentityPassword,

            }
        default:
            return state;

    }
}

