import httpClient from "../../core/httpclient";
import bookingType from "../type/booking";
import ApiUrl from '../../core/url'
import DownloadFile from "../../core/downloadFile";
import XMSErrorHandler from "../../core/xmsErrorHandler";

export const getBooking = (voyageId, setBookingState, getBookingState) => async dispatch => {
    const endPoint = `${ApiUrl}/voyage/getvoyagedetails`;
    // const reportVoyage = `${ApiUrl}/voyage/reportvoyagedeclaration`
    // const shipmentRequest = {
    //     voyageId: voyageId,
    //     statusDatetimeBegin: "",
    //     addDeclarationDetails: true
    // }
    setBookingState({ ...getBookingState, waitForRequest: true, bookingNotFound: false, noConnect: false })
    httpClient.post(endPoint, JSON.stringify(voyageId))
        .then(response => {
            dispatch({
                type: bookingType.GET_BOOKING,
                bookingData: response.data.result.bookings,
                allData: response.data.result
            });
            if (response.data.result.bookings.length < 1) {
                setBookingState({ ...getBookingState, waitForRequest: false, bookingNotFound: true, noConnect: false })
            } else {
                setBookingState({ ...getBookingState, waitForRequest: false, bookingNotFound: false, noConnect: false })
            }


        })
        .catch(error => {
            setBookingState({ ...getBookingState, waitForRequest: false, bookingNotFound: false, noConnect: true })
        })
        .finally(() => {
            //history.push("/booking/"+voyageId)
        });
}

export const deleteBookings = (BookingIdsDelete) => async dispatch => {
    
    const endpoint = `${ApiUrl}/booking/deletebookingsByBookingIds`;

    const DeleteRequest = BookingIdsDelete; 
    const requestBody = JSON.stringify(DeleteRequest);

    dispatch({
        type: bookingType.RESET_SELECTED_BOOKINGS,
    })

    return httpClient.post(endpoint, requestBody, { headers: {'Content-Type': 'application/json-patch+json'}})
        .then(repsonse => {})
        .catch((error) => {     
            const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
            dispatch({
                type: bookingType.DELETE_BOOKINGS_ERROR,
                ErrorCode: ErrorCode,
                ErrorDescription: ErrorDescription
            })
        })   
}

export const setDeleteBookings = (selectedBookingsForDelete, checked) => dispatch => {
    if(Object.keys(selectedBookingsForDelete)[0] !== "0"){
        const bookingId = selectedBookingsForDelete.bookingId;
        if(checked === true){
            dispatch({
                type: bookingType.SET_SELECTED_BOOKINGS_FOR_DELETE, 
                SelectedBookingIdForDelete: bookingId,
            })
        }
        else {
            dispatch({
                type: bookingType.REMOVE_SELECTED_BOOKINGS_FOR_DELETE,
                SelectedBookingIdForDelete: bookingId,
            })
        }     
    }            
    else {
        if(checked){
            const bookingIds = Object.values(selectedBookingsForDelete).map((booking) => booking.bookingId);
            console.log(bookingIds);
            dispatch({
                type: bookingType.SET_ALL_SELECTED_BOOKINGS_FOR_DELETE,
                BookingIds: bookingIds,
            })
        }
        else{
            dispatch({
                type: bookingType.SET_ALL_SELECTED_BOOKINGS_FOR_DELETE,
                BookingIds: [],
            })
        } 
    }
}

export const clearBooking = () => dispatch => {
    dispatch({
        type: bookingType.CLEAR_BOOKING
    })
}

export const filterByMatch = (matchStatus) => dispatch => {
    dispatch({
        type: bookingType.MATCH_FILTER,
        matchStatus: matchStatus
    })
}

export const WaitForUnmatchToMatch = (Status) => dispatch => {
    if (Status) {
        dispatch({
            type: bookingType.WAIT_FOR_UNMATCH_MATCH
        })
    } else {
        dispatch({
            type: bookingType.UNMATCH_MATCHED
        })
    }
}

export const reportVoyageExcel = (voyageId, callBack) => dispatch => {
    const reportVoyageEndPoint = `${ApiUrl}/voyage/reportvoyagedeclaration`
    let reportVoyageData = []
    httpClient.post(reportVoyageEndPoint, JSON.stringify(voyageId))
        .then(response => {
            reportVoyageData = response.data.result
            dispatch({
                type: bookingType.GET_REPORTS,
                reportsData: reportVoyageData,
            });
        })
        .finally(() => {
            if (typeof callBack === "function") {
                callBack(reportVoyageData)
            }
        })
}

export const setExcelModal = (ExcelModal) => dispatch => {
    dispatch({
        type: bookingType.SET_EXCELMODAL,
        ExcelModal: ExcelModal
    });
}

export const downloadBookingExcel = (Id, Filename, FinalFunc) => dispatch => {
    const downloadStatus = (status) => dispatch({
        type: bookingType.EXCEL_DOWNLOAD_STATUS,
        ExcelDownloadStatus: status
    })
    downloadStatus(true)
    const endPoint = `${ApiUrl}/voyage/downloadvoyagereport`
    DownloadFile(endPoint, JSON.stringify(Id), Filename).then(() => {
        downloadStatus(false)
        if (FinalFunc && typeof FinalFunc === "function") FinalFunc()
    }).catch((error) => {
        downloadStatus(false)
    })
}

export const setProcessExcel = (downloadStatus) => dispatch => {
    dispatch({
        type: bookingType.EXCEL_DOWNLOAD_STATUS,
        ExcelDownloadStatus: downloadStatus
    })
}

export const setMatchData = (matchData) => dispatch => {
    dispatch({
        type: bookingType.SET_SELECTED_MATCH_DECLARATION,
        matchData : matchData
    })
}