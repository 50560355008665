const connectionToServer = (getUserState, setUserState,authFunc) => {
    {
        if(getUserState.waitForRequest) return 0;
        const uNameValid = getUserState.userName.trim() !== "";
        const uPassValid = getUserState.userPass.trim() !== "";
        if (uNameValid && uPassValid) {
            authFunc(getUserState, setUserState)
        }
        else {
            setUserState({ ...getUserState, userNameValid: uNameValid, userPassValid: uPassValid,ErrorCode : 1, ErrorMessage: "Please fill the input" })
        }
        
    }
}
export default connectionToServer;