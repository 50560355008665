import React from 'react';
import './UserInput.css';
import { PasswordField, TextField } from '@dfds-ui/react-components';


const userInput = (props) => {
    const textField = <TextField
        className={props.className + " -nomargin"}
        name="myInput"
        placeholder={props.holder}
        onChange={props.onChange}
        value={props.value}
        onKeyPress={props.onKeyPress}
    />
    const passwordField = <PasswordField
        className={props.className + " -nomargin"}
        name="myInput"
        placeholder={props.holder}
        onChange={props.onChange}
        value={props.value}
        onKeyPress={props.onKeyPress}
    />
    return (
        <>
        <div className="input">
            <div className="imgCont">
                <span className="myIcon">{props.imgComp}</span>
            </div>
            {
                props.type === "password" ? passwordField : textField
            }
        </div>
        {
            !props.isValid && 
        <p className="errorMessageP">{(props.ValidationMessage && props.ValidationMessage !== "")? props.ValidationMessage : "This field is required."}</p>
        }
        </>
    );
}
export default userInput;