import React, { createRef } from 'react'
import JustDialog from '../JustDialog/JustDialog'
import { Button } from '@dfds-ui/react-components'
import './JustHamburger.scss'
import { GiHamburgerMenu } from "react-icons/gi";
import { More } from '@dfds-ui/icons'


export default class JustHamburger extends React.Component {
    constructor(props) {
        super(props)
        this.state = { Dialog: false }
        this.containerRef = createRef()

    }
    _onMouseLeave = (e) => {
        this.setState({ Dialog: false })
    }
    _onDialogBlur = (e) => {
        this.setState({ Dialog: false })
    }
    _onClick = (e) => {
        this.setState({ Dialog: true })
    }
    render() {
        const dialogOptions = this.props.DialogOptions
        const dialogPosition = this.props.DialogPosition ? this.props.DialogPosition : "Right"
        return (
            <>
                {this.state.Dialog && <JustDialog
                    ParentRef={this.containerRef}
                    onMouseLeave={this._onMouseLeave}
                    onBlur={this._onDialogBlur}
                    DialogOptions={dialogOptions}
                    to={dialogPosition}
                />}
                <div
                    className="JustTable-ContextMenuButton-Container"
                    // onBlur={(e) => this._onDialogBlur(e)}
                    // onMouseLeave={(e) => this._onMouseLeave(e)}
                    // onClick={(e) => this._onClick(e)}
                    ref={this.containerRef}
                >
                    <More
                        onClick={(e) => this._onClick(e)}
                        id="JustTable-ContextMenuButton" />

                </div>
            </>
        )
    }
}