import React from 'react'
import './SimpleGrid.scss';
const SimpleGrid = (props) => {
    const {Columns,Data} = props;
    if(Columns===undefined || Data===undefined) return <></>;
    return(
        <div className="SimpleGrid-Container">
            {
                Columns.map((ColumnInfo)=>{
                   return (
                   <div className="SimpleGrid-GridItem">
                       <p id="SimpleGrid-Data"><span id="title">{ColumnInfo.ColumnText}</span>{Data[ColumnInfo.DataId] }</p>
                       
                   </div>
                   )
                })
            }
        </div>
    );
}
{/* <p id="SimpleGrid-Data">{}</p> */}
export default SimpleGrid;