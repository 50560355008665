export const UnmatchDecTableSchema = {
    CheckTable: true,
    ColumnDesign: [
        {
            dataId: "provider",
            text: "Provider",

        },
        {
            dataId: "status",
            text: "Status",
        },
        {
            dataId: "tsn",
            text: "TSN",
            filter: "text",
            filterLabelText: "TSN",
            filterPlaceHolder: "Filter for TSN",
        },
        {
            dataId: "vehiclePlate",
            text: "VRN",
            filter: "text",
            filterLabelText: "VRN",
            filterPlaceHolder: "Filter for VRN",
        },
        {
            dataId: "unitNumber",
            text: "Unit Number",
            filter: "text",
            filterLabelText: "Unit Number",
            filterPlaceHolder: "Filter for Unit Number",
        },
        {
            dataId: "tsnGroup",
            text: "Group Number"
        },
        {
            dataId: "lrn",
            text: "LRN"
        },

        {
            dataId: "mrn",
            text: "MRN"
        },
        {
            dataId: "declarationDateEpoch",
            text: "Declaration Date",
        },

        {
            dataId: "totalNumberOfPack",
            text: "Package"
        },

        {
            dataId: "totalGrossMass",
            text: "Weight",
        },


    ],
    DataDesign: [
        {
            dataId: "provider",
        },
        {
            dataId: "statusName",
        },
        {
            dataId: "tsn",
        },
        {
            dataId: "vehiclePlate",
        },
        {
            dataId: "unitNumber",
        },
        {
            dataId: "tsnGroup",
        },
        {
            dataId: "lrn",
        },
        {
            dataId: "mrn",
        },
        {
            dataId: "declarationDateEpoch",
            type: "DATETIME"
        },
        {
            dataId: "totalNumberOfPack",

        },
        {
            dataId: "totalGrossMass",
            type: "number"
        },
    ],
}