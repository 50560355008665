import React from 'react'
import './RowCell.scss'
import PropTypes from 'prop-types';
import TableContext from '../../JustTableContext';
import LeftCalc from '../../../Helper/LeftCalculator';
import InputType from '../../../Helper/Component/InputTypes/InputTypes'
import DataTypes from '../../../Helper/Component/DataTypes/DataTypes'

export default class RowCell extends React.Component {
    constructor(props) {
        super(props)
        this.state = { turnInput: false, style: {}, dataList: props.HistoryDataList, initialized: false }
        this.tdRef = React.createRef();
        this.checkBoxRef = React.createRef();
    }
    _updateDataList = (newData, callBack) => {
        const dataDesign = this.props.DataDesign;
        const columnId = dataDesign.columnId
        const rowId = this.props.RowId
        const setCellList = () => {
            this.setState({
                dataList: [
                    ...this.state.dataList,
                    newData
                ]
            }, () => callBack(rowId, columnId, this.state.dataList))
        }
        this.props.DataHistoryListRegister(columnId, [...this.state.dataList, newData], setCellList)

    }
    _changeUIData = (newData) => {
        // this.setState({ cellData: newData })
    }
    componentDidMount() {
        this.setState({ ...this.state, initialized: true })
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (nextState !== this.state) {
            return true;
        }
        if (nextProps.HistoryDataList !== this.props.HistoryDataList) {
            this.setState({ dataList: nextProps.HistoryDataList })
            return false
        }
        if (nextProps.Data !== this.props.Data) {
            return true;
        }
        if (this.props.RowContextMenu !== nextProps.RowContextMenu) {
            return true
        }
        if (this.props.rowSelected !== nextProps.rowSelected) {
            return true
        }
        return false
    }
    UNSAFE_componentWillMount() {
        const columnDesigns = this.props.ColumnDesigns
        const designIndex = this.props.DesignIndex
        this.DesignLock = columnDesigns[designIndex].Options ? (columnDesigns[designIndex].Options.DesignLock) : false
        if (this.DesignLock.Default) {
            const totalWidth = LeftCalc(columnDesigns, designIndex)
            this.setState({
                style: {
                    position: "sticky",
                    left: totalWidth + "px",
                    // background: "white",
                    width: this.DesignLock.Width,
                }
            })
        }
    }
    onDblClick = (e) => {
        if (this.props.DataDesign.updatable) {
            this.setState({ turnInput: true })
        }
    }
    onInputBlur = (e) => {
        this.setState({ turnInput: false })
    }
    _inputOut = () => {
        this.setState({ turnInput: false })
    }
    _onKeyUp = (e) => {

    }
    _onKeyDown = (e) => {
        if (e.key === "Enter" && this.checkBoxRef && typeof this.checkBoxRef.click === "function") {
            this.checkBoxRef.click()
        }

        if (this.props.DataDesign.updatable) {
            if (!this.state.turnInput) {
                if (e.key === "Enter") {
                    this.setState({ turnInput: true })
                    return
                }
            } else {
                if (e.key === "Enter") {
                    this.setState({ turnInput: false })
                    return
                }
            }
        }
        const selectedRef = this.tdRef.current
        if (!this.state.turnInput) {
            switch (e.key) {
                case "ArrowUp":
                    const _selectedRow = selectedRef.parentElement
                    const _prevRow = _selectedRow.previousElementSibling
                    if (_prevRow === undefined || _prevRow === null) break
                    const _prevCell = _prevRow.cells[this.props.DesignIndex]
                    if (_prevCell === null || _prevCell === undefined) break;

                    _prevCell.focus()
                    break;
                case "ArrowDown":
                    const _selectedRow2 = selectedRef.parentElement
                    const _nextRow = _selectedRow2.nextElementSibling
                    if (_nextRow === undefined || _nextRow === null) break;
                    const _nextCell = _nextRow.cells[this.props.DesignIndex]
                    if (_nextCell === null || _nextCell === undefined) break;
                    _nextCell.focus()
                    break;
                case "ArrowLeft":
                    if (selectedRef.previousElementSibling === null ||
                        selectedRef.previousElementSibling === undefined) break;
                    selectedRef.previousElementSibling.focus()
                    break;
                case "ArrowRight":
                    if (selectedRef.nextElementSibling === null ||
                        selectedRef.nextElementSibling === undefined) break;
                    selectedRef.nextElementSibling.focus()
                    break;
                default:
                    break;
            }
        }
    }
    render() {
        const cellIndex = this.props.DesignIndex
        const rowIndex = this.props.RowIndex
        const columnLength = this.props.ColumnDesigns.length
        const tabIndex = cellIndex + (rowIndex * columnLength) + 1
        const rowId = this.props.RowId
        const dataDesign = this.props.DataDesign;
        const columnId = dataDesign.columnId
        const dataInput = dataDesign.input
        const dataInputType = dataInput ? dataInput.Type : "text"
        const rowContextMenu = this.props.RowContextMenu
        const rowContextActions = this.props.RowContextActions
        const numberDigit = dataDesign.numberDigit
        const updatableCell = dataDesign.updatable
        const rowLocked = this.props.RowLock
        const selectedRow = this.props.rowSelected
        const displayData = (dataInputType === "checkbox" || dataDesign.dataType === "JustTable-RowSelect-CheckBox") ? "" :
            DataTypes(dataDesign.dataType, this.props.Data
                , dataDesign.dataCheck, rowContextMenu, rowContextActions, this.props.RowData, numberDigit,
                (dataDesign.dataLanguage ? dataDesign.dataLanguage : "en"), updatableCell, rowLocked)
        const dataInformation = {
            rowId: rowId,
            columnId: columnId,
            data: this.props.Data
        }

        const onKeyUp = dataInput ? dataInput.onKeyUp : null
        return (
            <TableContext.Consumer>
                {justContext => {
                    return (
                        <td
                            ref={this.tdRef}
                            onClick={(e) => justContext.onRowCellClick(e, dataInformation)}
                            onKeyPress={this._onKeyUp}
                            onKeyDown={this._onKeyDown}
                            tabIndex={tabIndex}
                            style={this.state.style}
                            onDoubleClick={(e) => this.onDblClick(e)}
                            data-field={dataDesign.columnId}
                            data-lock={this.DesignLock ? "on" : "off"}
                            className={"JustTable-RowCell"}>
                            {
                                (!(dataDesign.dataType === "JustTable-RowSelect-CheckBox")) ?
                                    (!rowLocked || dataInputType === "checkbox") ?
                                        (this.state.turnInput || dataInputType === "checkbox") ?
                                            <InputType
                                                inputRef={(input) => this.checkBoxRef = input}
                                                TabIndex={tabIndex}
                                                InputType={dataInputType}
                                                Name={dataDesign.columnId}
                                                onBlur={(e) => this.onInputBlur(e)}
                                                DefaultValue={this.props.Data}
                                                ChangeData={justContext.changeData}
                                                ChangeUIData={this._changeUIData}
                                                UpdateDataList={this._updateDataList}
                                                DataList={this.state.dataList}
                                                DataType={dataDesign.dataType}
                                                CheckValue={this.props.Data}
                                                Init={false}
                                                onRowUpdate={this.props.onRowUpdate} // to green
                                                SetRow={this.props.SetRow}
                                                ParentIds={this.props.ParentIds}
                                                InputEvents={justContext.inputEvents}
                                                onKeyUp={onKeyUp}
                                                inputOut={this._inputOut}
                                                RowLocked={rowLocked}
                                                RowIndex={rowIndex}// for saving row to data history
                                                RowData={this.props.RowData} // for saving row to data history
                                            />
                                            :
                                            (displayData ? displayData : <span id="JustPlace-Holder">{dataInput.PlaceHolder}</span>)
                                        :
                                        displayData
                                    :
                                    <span id="JustTable-CheckBox">
                                        <input type="checkbox"
                                            ref={(input) => this.checkBoxRef = input}
                                            onChange={(e) => {
                                                justContext.onSelected(this.props.RowData, e.target.checked, this.tdRef);

                                            }}
                                            className="JustTable-RowSelect-CheckBox"
                                            checked={selectedRow}
                                        />
                                    </span>

                            }
                        </td>)
                }
                }
            </TableContext.Consumer>
        )
    }
}
RowCell.propTypes = {
    data: PropTypes.any,
    dataDesign: PropTypes.object,
    columnId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    designIndex: PropTypes.number,
    rowIndex: PropTypes.number,
    columnLenght: PropTypes.number,
    rowId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
RowCell.defaultProps = {
    data: null,
    columnId: "",
    designIndex: 0,
    rowIndex: 0,
    rowId: 0,
    columnLenght: 0,
    dataDesign: {}
}