import adminPanelTypes from "../type/adminPanel";
import httpClient from "../../core/httpclient";
import ProjectConfig from "../../config/config";
import XMSErrorHandler from "../../core/xmsErrorHandler";

export const getUsers = () => dispatch => {
    const endPoint = `${ProjectConfig.apiUrl}/user/getall`
    //başladı
    dispatch({
        type: adminPanelTypes.GETTING_USERS
    })
    httpClient.post(endPoint, null)
        .then((response) => {
            const users = response.data.result
            dispatch({
                type: adminPanelTypes.GET_USERS,
                Users: users
            })
        })
        .catch((error) => {
            const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
            dispatch({
                type: adminPanelTypes.GET_USERS_ERROR,
                ErrorCode: ErrorCode,
                ErrorDescription: ErrorDescription
            })
            //hata
        })
}
export const getUserRoles = () => dispatch => {
    const endPoint = `${ProjectConfig.apiUrl}/user/getroles`
    //başladı
    dispatch({
        type: adminPanelTypes.GETTING_USER_ROLES
    })
    httpClient.post(endPoint, null)
        .then((response) => {
            const roles = response.data.result
            dispatch({
                type: adminPanelTypes.GET_USER_ROLES,
                Roles: roles,
                IdentityRole: roles[0],
            })
        })
        .catch((error) => {
            const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
            dispatch({
                type: adminPanelTypes.GET_USER_ROLES_ERROR,
                ErrorCode: ErrorCode,
                ErrorDescription: ErrorDescription
            })
            //hata
        })
}
export const saveUser = (IdentityUsername, IdentityName, IdentitySurname, IdentityEmail, IdentityRole,
    IdentityStatus, IdentityCompanyCode, IdentityDepartment, IdentityPassword) => dispatch => {
        const endPoint = `${ProjectConfig.apiUrl}/user/save`
        debugger
        //başladı
        dispatch({
            type: adminPanelTypes.SAVING_USER
        })
        if (!IdentityUsername) return
        const user = {
            Username: IdentityUsername.toUpperCase(),
            Email: IdentityEmail,
            Name: IdentityName,
            Surname: IdentitySurname,
            Roles: [IdentityRole],
            Status: 1,
            CompanyCode: IdentityCompanyCode,
            Department: IdentityDepartment,
            Password: IdentityPassword ? IdentityPassword : '123456',
        }

        httpClient.post(endPoint, user)
            .then((response) => {
                dispatch({
                    type: adminPanelTypes.SAVE_USER,
                })
            })
            .catch((error) => {
                const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
                dispatch({
                    type: adminPanelTypes.SAVE_USER_ERROR,
                    ErrorCode: ErrorCode,
                    ErrorDescription: ErrorDescription
                })
                //hata
            })
    }
export const deleteUser = (username) => dispatch => {
    const endPoint = `${ProjectConfig.apiUrl}/user/deleteuser`
    //başladı
    dispatch({
        type: adminPanelTypes.DELETING_USER
    })
    //debugger
    httpClient.post(endPoint, JSON.stringify(username))
        .then((response) => {
            dispatch({
                type: adminPanelTypes.DELETE_USER,
            })
        })
        .catch((error) => {
            const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
            dispatch({
                type: adminPanelTypes.DELETE_USER_ERROR,
                ErrorCode: ErrorCode,
                ErrorDescription: ErrorDescription
            })
            //hata
        })
}
export const IdentityUserModalShow = () => dispatch => {
    dispatch({
        type: adminPanelTypes.IDENTITY_USER_MODAL_SHOW,
    })
}
export const IdentityUserModalHide = () => dispatch => {
    dispatch({
        type: adminPanelTypes.IDENTITY_USER_MODAL_HIDE,
    })
}
export const setSelectedUser = (Row) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_SELECTED_USER,
        SelectedUser: Row
    })
}
export const deleteModalShow = (Row) => dispatch => {
    dispatch({
        type: adminPanelTypes.DELETE_USER_MODAL_SHOW,
        IdentityUsername: Row.username
    })
}
export const deleteModalHide = () => dispatch => {
    dispatch({
        type: adminPanelTypes.DELETE_USER_MODAL_HIDE,
        SelectedUser: null,
    })
}
export const setError = (ErrorCode, ErrorDescription) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_ERROR,
        ErrorCode: ErrorCode,
        ErrorDescription: ErrorDescription
    })
}
export const clearError = () => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_ERROR,
        ErrorCode: -1,
        ErrorDescription: null
    })
}

export const setNetworkError = (ErrorCode, ErrorDescription) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_NETWORK_ERROR,
        ErrorCode: ErrorCode,
        ErrorDescription: ErrorDescription
    })
}
export const clearNetworkError = () => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_NETWORK_ERROR,
        ErrorCode: -1,
        ErrorDescription: ""
    })
}
export const setInputIdentityUsername = (value) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_IDENTITY_USER_USER_NAME,
        IdentityUsername: value
    })
}
export const setInputIdentityName = (value) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_IDENTITY_USER_NAME,
        IdentityName: value
    })
}
export const setInputIdentitySurname = (value) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_IDENTITY_USER_SURNAME,
        IdentitySurname: value
    })
}
export const setInputIdentityEmail = (value) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_IDENTITY_USER_EMAIL,
        IdentityEmail: value
    })
}
export const setInputIdentityRole = (value) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_IDENTITY_USER_ROLE,
        IdentityRole: value
    })
}
export const setInputIdentityStatus = (value) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_IDENTITY_USER_STATUS,
        IdentityStatus: value
    })
}
export const setInputIdentityCompanyCode = (value) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_IDENTITY_USER_COMPANY_CODE,
        IdentityCompanyCode: value
    })
}
export const setInputIdentityDepartment = (value) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_IDENTITY_USER_DEPARTMENT,
        IdentityDepartment: value
    })
}
export const setInputIdentityPassword = (value) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_IDENTITY_USER_PASSWORD,
        IdentityPassword: value
    })
}
