import React from 'react'
import { connect } from 'react-redux';
import { filterByMatch } from "../../../store/action/bookingAction"
import SimpleGrid from '../../CommonUI/SimpleGrid/SimpleGrid'
import { Refresh, Delete} from '@dfds-ui/icons';
import { bookingPageLanguage } from '../../../assets/language/language'
import { FaRegFileExcel } from 'react-icons/fa';

const language = "en";
const BookingTableHeader = (props) => {
    const { VoyageInfo, GetBookingState, SetBookingState,
        GetBookings, filterByMatch,
        BookingData, VoyageSummaryData, WaitForRequest, OnExcelClick, OnDeleteButtonClick, PermissionToUseButtonDelete } = props;

    return (

        <div className="bookingTableHeader">

            <select className="browser-default custom-select matchSelect" onChange={
                (event) => filterByMatch(event.target.value)

            }>
                <option value={"ALL"} selected>{bookingPageLanguage[language].MatchStatusAll}</option>
                <option value={"MATCHED"}>{bookingPageLanguage[language].MatchedStatus}</option>
                <option value={"MATCHEDWITHERRORS"}>{"Matched With Errors"}</option>
                <option value={"UNMATCHED"}>{bookingPageLanguage[language].UnmatchedStatus}</option>
            </select>

            <h4 id="voyageInfo">{VoyageInfo.voyageNumber}</h4>
            {
                WaitForRequest ?
                    <SimpleGrid
                        Columns={[{ ColumnText: bookingPageLanguage[language].VoyageSummary.Bookings, DataId: "tBookings" },
                        { ColumnText: bookingPageLanguage[language].VoyageSummary.MBookings, DataId: "tMatching" },
                        { ColumnText: bookingPageLanguage[language].VoyageSummary.UMBookings, DataId: "tUnMatching" },
                        { ColumnText: bookingPageLanguage[language].VoyageSummary.Ratio, DataId: "mRadio" },
                        { ColumnText: bookingPageLanguage[language].VoyageSummary.TsnCount, DataId: "tTsn" },
                        { ColumnText: bookingPageLanguage[language].VoyageSummary.TotalBookingQuantity, DataId: "tBookingQuantity" },
                        { ColumnText: bookingPageLanguage[language].VoyageSummary.TotalGrossWeight, DataId: "tGrossWeight" },]}
                        Data={{
                            tBookings: "?",
                            tMatching: "?",
                            tUnMatching: "?",
                            tTsn: "?",
                            mRadio: "%?",
                            tGrossWeight: "?",
                            tBookingQuantity: "?"
                        }}
                    />
                    :

                    <SimpleGrid
                        Columns={[{ ColumnText: bookingPageLanguage[language].VoyageSummary.Bookings, DataId: "tBookings" },
                        { ColumnText: bookingPageLanguage[language].VoyageSummary.MBookings, DataId: "tMatching" },
                        { ColumnText: bookingPageLanguage[language].VoyageSummary.UMBookings, DataId: "tUnMatching" },
                        { ColumnText: bookingPageLanguage[language].VoyageSummary.Ratio, DataId: "mRadio" },
                        { ColumnText: bookingPageLanguage[language].VoyageSummary.TsnCount, DataId: "tTsn" },
                        { ColumnText: bookingPageLanguage[language].VoyageSummary.TotalBookingQuantity, DataId: "tBookingQuantity" },
                        { ColumnText: bookingPageLanguage[language].VoyageSummary.TotalGrossWeight, DataId: "tGrossWeight" },]}

                        Data={{

                            tBookings: VoyageSummaryData ? VoyageSummaryData.totalBookings : "?",
                            tMatching: VoyageSummaryData ? VoyageSummaryData.totalMatchingBookings : "?",
                            tUnMatching: VoyageSummaryData ? VoyageSummaryData.numberOfUnmatchedBookings : "?",
                            tTsn: VoyageSummaryData ? VoyageSummaryData.totalTsnNumber : "?",
                            mRadio: VoyageSummaryData ? "%" + ((VoyageSummaryData.matchRatio * 100)).toFixed(2) : "%?",
                            tGrossWeight: VoyageSummaryData ? VoyageSummaryData.totalBookingGrossWeight : "?",
                            tBookingQuantity: VoyageSummaryData ? VoyageSummaryData.totalBookingQuantity : "?"
                        }}
                    />


            }
            {
                //---------------
                BookingData && Object.keys(BookingData).length > 0 &&
                <FaRegFileExcel className={"ExcelButton"} onClick={() => { OnExcelClick() }} />
                //---------------
            }
            <Refresh
                className={"refreshBtn " + (WaitForRequest ? "refreshing" : "")}
                onClick={() => GetBookings(VoyageInfo.voyageId, SetBookingState, GetBookingState)} />

            {
                PermissionToUseButtonDelete &&
                <Delete
                    className={"DeleteButton"} 
                    onClick={OnDeleteButtonClick}
                />
            }
            
        </div>
    );
}

export default connect(null, { filterByMatch})(BookingTableHeader); 