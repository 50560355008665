import React from 'react'
import './DeclarationHeader.scss'
// import DateInput from '../../CommonUI/DateInput/DateInput'
import SearchButton from '../../CommonUI/DotAnimButton/DotAnimButton'
import { TextField, Label } from '@dfds-ui/react-components'
import { connect } from 'react-redux'
import { getDeclarations, setDeclarationDate, setDeclarationReleaseNumber, setDeclarationTsn, setDeclarationUnitNumber } from '../../../store/action/declarationAction'
import DatePicker from 'react-datepicker'
const DeclarationHeader = (props) => {
    const { StartDate, EndDate, Tsn, ReleaseNumber, UnitNumber, getDeclarations,
        setDeclarationDate, setDeclarationReleaseNumber, setDeclarationTsn, setDeclarationUnitNumber, WaitForDeclarations } = props
    const _onSearchClick = () => {
        getDeclarations(StartDate, EndDate, UnitNumber, ReleaseNumber, Tsn)
    }
    return (
        <div className="DeclarationHeader-Container">
            <div className="TextField-Container">
                <div className="TextField-Wrapper">
                    <TextField name="declarationTsn" label="TSN" size={"small"} value={Tsn}
                        placeholder={"Enter TSN"}
                        onChange={event => { setDeclarationTsn(event.target.value) }} />
                </div>
                <div className="TextField-Wrapper">
                    <TextField name="declarationReleaseNumber" label="Release Number" size={"small"} value={ReleaseNumber}
                        placeholder={"Enter Release Number"}

                        onChange={event => { setDeclarationReleaseNumber(event.target.value) }} />
                </div>
                <div className="TextField-Wrapper">
                    <TextField name="declarationUnitNumber" label="Unit Number" size={"small"} value={UnitNumber}
                        placeholder={"Enter Unit Number"}
                        onChange={event => { setDeclarationUnitNumber(event.target.value) }} />
                </div>
                <div className="DateField-Wrapper">
                    <Label id="declarationDate-Label">
                        Declaration Date
                    </Label>
                    <DatePicker
                        className="DeclarationDate-Picker"
                        selected={StartDate && new Date(StartDate)}
                        onChange={date => setDeclarationDate(date)}
                        isClearable
                        placeholderText="Enter Declaration Date"
                        dateFormat={"dd/MM/yyyy"}
                    />
                </div>
            </div>

            <SearchButton
                className="searchBtn"
                ButtonLabel="SEARCH"
                waitForRequest={WaitForDeclarations}
                onClick={() => _onSearchClick()}
            />
        </div>

    )
}
const mapStateToProps = state => {
    return {
        StartDate: state.declaration.StartDate,
        EndDate: state.declaration.EndDate,
        Tsn: state.declaration.Tsn,
        ReleaseNumber: state.declaration.ReleaseNumber,
        UnitNumber: state.declaration.UnitNumber,
        WaitForDeclarations: state.declaration.WaitForDeclarations,
    }
}

export default connect(mapStateToProps, { getDeclarations, setDeclarationDate, setDeclarationReleaseNumber, setDeclarationTsn, setDeclarationUnitNumber })(DeclarationHeader);
