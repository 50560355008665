import React from 'react';
import { Ship2, AlertWarning } from '@dfds-ui/icons';
import SpinDiv from '../../CommonUI/Spindiv/Spindiv'
import NoVoyage from '../../CommonUI/TableError/TableError';
import NetworkError from '../../CommonUI/TableError/TableError';
import PerfectTable from '../../CommonUI/Table/PerfectTable'
import { VoyageTableSchema , DummyVoyageData} from './VoyageTableSchema'

import {voyagePageLanguage} from '../../../assets/language/language'
const language = "en";
const VoyageTable = ({ GetVoyageState, VoyageFilterData,OnRowClick, StaticTable}) => 
{
    return (tableContent(GetVoyageState, VoyageFilterData, OnRowClick, StaticTable));
}

const tableContent = (getVoyageState, VoyageFilterData, rowClickEvent, StaticTable) => {
    const NoConnectionIco = <AlertWarning className="searchLogo" width="50px" height="50px" />
    const rowDivs = [
        {
            show : getVoyageState.voyageNotFound,
            component : <NoVoyage ShowLogo={true} Message={voyagePageLanguage[language].NoVoyageMessage} />
        },
        {
            show : getVoyageState.noConnect,
            component : <NetworkError Logo={NoConnectionIco} ShowLogo={true} Message={voyagePageLanguage[language].NoConnectionMessage} />
        },
        {
            show : getVoyageState.waitForRequest,
            component : <SpinDiv waitForRequest={getVoyageState.waitForRequest} ShowLogo={false} size={50} />
        },
    ]
    return (
        <div className="voyageTableContent">
            <PerfectTable
                DataArray={VoyageFilterData}
                TableSchema={VoyageTableSchema}
                OnRowClick={rowClickEvent}
                RowRenderComps={rowDivs}
            />

        </div>
    );
}
export default VoyageTable;