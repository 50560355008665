import React from 'react'
import { Modal } from '@dfds-ui/modal'
import { Button } from '@dfds-ui/react-components';
import { LeftArrow } from '@dfds-ui/icons';
import './YesNoModal.scss';
import SpinButton from '../../SpinButton/SpinButton';
const YesNoModal = (props) => {
    const { OpenModal, OnClose, Title, onYesClick, onNoClick, Message, yesButtonComp, waitForRequest } = props;
    return (
        <Modal
            className="YesNoModal"
            isOpen={OpenModal}
            onRequestClose={OnClose}
            heading={
                <span className="YesNoModalTitle">
                    {Title}
                </span>
            }
            headerClassName="headerYesOrNo">
            <p id="YesNoMessage">{Message}</p>
            {

                !yesButtonComp ?
                    <SpinButton
                        className="YesButton"
                        onClick={onYesClick}
                        waitForRequest={waitForRequest}
                        variation="secondary"
                        iconAlign="left"
                        ButtonLabel = {"YES"}
                        /> :
                    yesButtonComp
            }
            <Button className="NoButton"
                onClick={onNoClick ? onNoClick : OnClose}
                variation="secondary"
                iconAlign="left">
                No
            </Button>
        </Modal>
    );
}
export default YesNoModal;
