import isHandlerEnabled from './interceptor.checker';
import {getUserStorage} from '../ens_storage'
const authenticationInterceptorHandler = (request) => {
    if (isHandlerEnabled(request)) {
        const userStorage = getUserStorage()
        request.headers.common['Authorization'] = 'Bearer ' +  userStorage.access_token;
        request.headers.common['Access-Control-Allow-Origin'] = "*"
    }
    return request;
}

export default authenticationInterceptorHandler;

//TODO: Local store yerine şunu implemente etmek lazım
//https://michaelwashburnjr.com/best-way-to-store-tokens-redux/