const bookingTypes = Object.freeze({
    GET_BOOKING: Symbol('GET_BOOKING'),
    CLEAR_BOOKING: Symbol('CLEAR_BOOKING'),
    MATCH_FILTER: Symbol('MATCH_FILTER'),
    WAIT_FOR_UNMATCH_MATCH: Symbol('WAIT_FOR_UNMATCH_MATCH'),
    UNMATCH_MATCHED: Symbol('UNMATCH_MATCHED'),
    GET_REPORTS: Symbol('GET_REPORTS'),
    SET_EXCELMODAL: Symbol('SET_EXCELMODAL'),
    EXCEL_DOWNLOAD_STATUS: Symbol('EXCEL_DOWNLOAD_STATUS'),
    SET_SELECTED_MATCH_DECLARATION: Symbol('SET_SELECTED_MATCH_DECLARATION'),
    SET_SELECTED_BOOKINGS_FOR_DELETE: Symbol('SET_SELECTED_BOOKINGS_FOR_DELETE'),
    REMOVE_SELECTED_BOOKINGS_FOR_DELETE: Symbol('REMOVE_SELECTED_BOOKINGS_FOR_DELETE'),
    DELETING_BOOKINGS: Symbol('DELETING_BOOKINGS'),
    DELETE_BOOKINGS: Symbol('DELETE_BOOKINGS'),
    DELETE_BOOKINGS_ERROR: Symbol('DELETE_BOOKINGS_ERROR'),
    SET_ALL_SELECTED_BOOKINGS_FOR_DELETE: Symbol('SET_ALL_SELECTED_BOOKINGS_FOR_DELETE'),
    RESET_SELECTED_BOOKINGS: Symbol('RESET_SELECTED_BOOKINGS'),
});

export default bookingTypes;