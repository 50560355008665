import voyageType from '../type/voyage';
import { decreaseDate, increaseDate } from '../../core/dateStr'
const initialState = {
    voyageData: null,
    voyageRequestData: {
        StartDate: decreaseDate(3),
        EndDate: increaseDate(1),
        DeparturePort: null,
        ArrivalPort: null
    },
    voyageFilterData: null,
    voyagePorts: null,

}
export default (state = initialState, action) => {
    switch (action.type) {
        case voyageType.GET_VOYAGE:
            return {
                ...state,
                voyageData: {
                    ...action.voyageData
                },
                voyageRequestData: {
                    ...action.voyageRequestData
                },

                voyageFilterData: {
                    ...action.voyageData
                }
            }
        case voyageType.GET_PORTS: //burası
            return {
                ...state,
                voyagePorts: action.voyagePorts
            }
        case voyageType.SET_VOYAGEDATE:
            return {
                ...state,
                voyageRequestData: {
                    ...state.voyageRequestData,
                    StartDate: action.StartDate,
                    EndDate: action.EndDate
                }
            }
        case voyageType.CLEAR_VOYAGE:
            return {
                ...state,
                voyageData: null,
                voyageRequestData: {
                    StartDate: decreaseDate(3),
                    EndDate: increaseDate(1),
                    DeparturePort: null,
                    ArrivalPort: null
                },
                voyageFilterData: null,
                // voyagePorts: null
            }
        case voyageType.FILTER_VOYAGE:
            return {
                ...state,
                voyageFilterData: action.voyageFilterData,
                voyageRequestData: {
                    StartDate: state.voyageRequestData.StartDate,
                    EndDate: state.voyageRequestData.EndDate,
                    DeparturePort: action.filteredPorts.DeparturePort,
                    ArrivalPort: action.filteredPorts.ArrivalPort
                }
            }
        default:
            return state;

    }
}

