import React from 'react';
import Footer from './Footer/Footer'
import ENSRoutes from '../RoutedComponents/Routes/index'
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../component/Header/Header'

const App = () => {
    return (
        <div className="App">
            <Header />
            <ENSRoutes/>
            <Footer />
        </div>

    );
}

export default App;