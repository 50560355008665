import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import VoyageTable from './VoyageTable/VoyageTable'
import VoyageTableHeader from './VoyageTable/VoyageTableHeader'
import { getVoyage, filterVoyage, setVoyageDate, getPorts } from "../../store/action/voyageAction"
import history from '../../core/history'
import { ErrorModal } from '../CommonUI/Modals/ErrorModal'
import './Voyage.scss'

import { voyagePageLanguage } from '../../assets/language/language';
const language = "en";

const Voyage = props => {
    const [getVoyageState, setVoyageState] = useState({
        waitForRequest: false,
        dateRangeValid: true,
        errorMessage: "",
        showError: false,
        startLabel: voyagePageLanguage[language].StartDate,
        endLabel: voyagePageLanguage[language].EndDate,
        voyageNotFound: false,
        notSearched: true,
        noConnect: false,
    })
    const rowClickEvent = (voyage) => {
        history.push({
            pathname: '/booking',
            state: { voyageInfo: voyage }
        })
    }
    useEffect(() => {
        if (props.voyageRequestData.StartDate === null || props.voyageRequestData.EndDate === null) {
            setVoyageState({ ...getVoyageState, notSearched: false })
        }
        props.getPorts()
    }, [])
    useEffect(() => {
        if ((props.voyageRequestData.StartDate !== null || props.voyageRequestData.EndDate !== null) && getVoyageState.notSearched) {
            props.getVoyage(getVoyageState, setVoyageState, props.voyageRequestData.StartDate,
                props.voyageRequestData.EndDate, props.voyageRequestData.ArrivalPort,
                props.voyageRequestData.DeparturePort, props.filterVoyage)
        }
    }, [getVoyageState.notSearched])
    return (
        <>
            {
                getVoyageState.showError &&
                <ErrorModal
                    ErrorTitle="Input Error"
                    ErrorMessage={getVoyageState.errorMessage}
                    OnClose={() => setVoyageState({ ...getVoyageState, showError: false })}
                />
            }
            <div className="voyageTableContainer">
                {/* <h2 className="title">{voyagePageLanguage[language].VoyageHeader}</h2> */}
                <VoyageTableHeader
                    GetVoyageState={getVoyageState}
                    SetVoyageState={setVoyageState}
                    FilterTable={props.filterVoyage}
                    VoyageData={props.voyageData}
                    StartDate={props.voyageRequestData.StartDate}
                    EndDate={props.voyageRequestData.EndDate}
                    ArrivalPort={props.voyageRequestData.ArrivalPort}
                    DeparturePort={props.voyageRequestData.DeparturePort}
                    GetVoyages={props.getVoyage}
                    SetVoyageDate={props.setVoyageDate}
                    VoyagePorts={props.voyagePorts}
                />

                <VoyageTable
                    GetVoyageState={getVoyageState}
                    VoyageFilterData={props.voyageFilterData}
                    OnRowClick={rowClickEvent}
                    StaticTable={false}
                />

            </div>
        </>
    );
}
const mapStateToProps = state => {
    return {
        voyageData: state.voyage.voyageData,
        voyageRequestData: state.voyage.voyageRequestData,
        voyageFilterData: state.voyage.voyageFilterData,
        voyagePorts: state.voyage.voyagePorts
    }
}
export default connect(mapStateToProps, { getVoyage, filterVoyage, setVoyageDate, getPorts })(Voyage);