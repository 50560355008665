import moment from 'moment';
export const epochToShortDate = (epochTime, Utc) => {
    if (epochTime) {
        if (Utc) {
            return moment(epochTime * 1000).format('DD-MM-YYYY HH:mm');
        } else {
            return moment.unix(epochTime).subtract(moment.unix(epochTime).utcOffset() * 60 * 1000).format('DD-MM-YYYY');
        }
    } else {
        return ""
    }
}
export const epochToDateTime = (epochTime, Utc) => {
    if (epochTime) {
        if (Utc) {
            return moment(epochTime * 1000).format('DD-MM-YYYY HH:mm');
        } else {
            return moment.unix(epochTime).subtract(moment.unix(epochTime).utcOffset() * 60 * 1000).format('DD-MM-YYYY HH:mm');
        }
    } else {
        return ""
    }
}
export const epochToDateTimeNoFormat = (epochTime, Utc) => {
    if (epochTime) {
        if (Utc) {
            return moment(epochTime * 1000).format('DDMMYYYYHHmm');
        } else {
            return moment.unix(epochTime).subtract(moment.unix(epochTime).utcOffset() * 60 * 1000).format('DDMMYYYYHHmm')
        }
    } else {
        return ""
    }
}
export const epochToSimpleDate = (epochTime) => {
    return moment(epochTime).format('YYYYMMDDHHmmss')
}