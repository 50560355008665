import React from 'react'
import ReactDOM from 'react-dom'
import './JustDialog.scss'
import PropTypes from 'prop-types';
import JustPortal from '../JustPortal/JustPortal';

export default class JustDialog extends React.Component {
    constructor(props) {
        super(props)
        this.state = { DialogStyle: null, selfRef: null }
    }
    shouldComponentUpdate(nextProps, nextStates) {
        if (nextStates.selfRef !== this.state.selfRef) {
            nextStates.selfRef.focus()
            return false
        }
        return true
    }
    componentWillMount() {
        const relatedRef = this.props.ParentRef.current
        if (relatedRef !== undefined && relatedRef !== null) {
            const domNode = ReactDOM.findDOMNode(relatedRef)
            var relatedRef_Position = domNode.getBoundingClientRect()
            var offSetTop = this.props.to === "Right" ? (relatedRef_Position.height - 1 /*border */) : 0

            this.setState({
                DialogStyle: {
                    top: (relatedRef_Position.bottom - offSetTop) + "px",
                    left: (relatedRef_Position.right) + "px"
                }
            })
        }
    }
    render() {
        const dialogStyle = this.state.DialogStyle
        const dialogOptions = this.props.DialogOptions ? this.props.DialogOptions : []
        return (
            <JustPortal portalId="JustPortal-JustDialog-Portal">
                <div ref={elem => this.setState({ selfRef: elem })}
                    tabIndex="0" onBlur={(e) => this.props.onBlur(e)}
                    onMouseLeave={(e) => this.props.onMouseLeave(e)}
                    style={dialogStyle} className="JustDialog-Container">
                    {
                        dialogOptions.map((dOption, key) => {
                            return (
                                <div key={key} onClick={(e) => {
                                    if (dOption.Active) {
                                        dOption.onClick()
                                        this.props.onBlur()
                                    }
                                }} className={(dOption.Active ? "JustDialog-Text-Container" : "JustDialog-Option-Disabled")}>
                                    <span id="JustDialog-Icon">{dOption.Icon}</span>
                                    <span id="JustDialog-Text">{dOption.Text}</span>
                                </div>
                            )
                        })
                    }
                </div>
            </JustPortal >
        )
    }
}
JustDialog.propTypes = {
    Show: PropTypes.bool,
    DialogOptions: PropTypes.array,

};