import React from 'react';
import Booking from '../component/Booking/Booking'
import { Route, Redirect } from 'react-router-dom';
// import {isTokenExist} from '../core/authentication'
import { pageChanged } from '../store/action/pageActions';

import { connect } from 'react-redux'

const booking = (props) => {
    const { pageChanged } = props
    pageChanged("VOYAGES")

    if (props.location.state === undefined || props.location.state === null) {
        return <Route render={props => (
            <Redirect to={{ pathname: '/voyage', state: { from: props.location } }} />
        )} />
    }
    return <Booking {...props} />
}
export default connect(null, { pageChanged })(booking);
