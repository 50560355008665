import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    getBooking, reportVoyageExcel, clearBooking, setExcelModal,
    filterByMatch, WaitForUnmatchToMatch, setProcessExcel, downloadBookingExcel, deleteBookings
} from "../../store/action/bookingAction"
import BookingTable from './BookingTable/BookingTable'
import BookingTableHeader from './BookingTable/BookingTableHeader'
import './Booking.scss'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import history from '../../core/history'
import TsnHistory from './TsnHistoryTable/TsnHistoryTable';
import UnmatchDec from './UnmatchDeclarationTable/UnmatchDecTable'
import DeleteTsnModal from '../CommonUI/Modals/YesNoModal/YesNoModal'
import httpClient from "../../core/httpclient";
import ApiUrl from '../../core/url'
import ExcelModal from '../CommonUI/Modals/YesNoModal/YesNoModal'
import DeleteBookingsModal from '../CommonUI/Modals/YesNoModal/YesNoModal'
import DownloadFile from '../../core/downloadFile'
import { epochToSimpleDate } from '../../core/epochToDateConverter';
import { getUsername } from '../../core/authentication';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {IsAdmin} from '../../core/IsAdmin';

const Booking = (props) => {
    const { location, bookingData, getBooking, clearBooking,
        filterByMatch, allDataOfVoyage, reportVoyages,
        WaitForUnmatchToMatch, OpenExcelModal, reportVoyageExcel,
        setExcelModal, setProcessExcel, ExcelDownloadStatus, downloadBookingExcel, WaitForDeleteBookings, deleteBookings, BookingIdsDelete  } = props;

    const voyageInfo = location.state.voyageInfo
    const voyageId = location.state.voyageInfo.voyageId
    const voyageNumber = location.state.voyageInfo.voyageNumber
    const [getBookingState, setBookingState] = useState({
        waitForRequest: true,
        errorMessage: "",
        showError: false,
        bookingNotFound: false,
        noConnect: false,

    })
    const [selectedTsn, setSelectedTsn] = useState(null);
    const [UnmatchedDeclarionsOpen, setUMD] = useState(false);
    const [DeleteDETsn, setDeleteDETsn] = useState(null);
    const [OpenDeleteModal, setOpenDeleteModal] = useState(false);
    const [waitForDelete, setWaitForDelete] = useState(false)
    const [MatchBooking, setMatchBooking] = useState(null);
    const [DeleteOption, setDeleteOption] = useState("")
    const UnmatchedDeclarations_onCloseEvent = () => {
        setUMD(false);
    }
    const [OpenDeleteBookingsModal, setOpenDeleteBookingsModal] = useState(false);

    useEffect(() => {
        clearBooking();
        getBooking(voyageId, setBookingState, getBookingState);
    }, [])

    const bookingClickEvent = (bookingId) => {
    }
    const TsnClickEvent = (row) => {
    }
    const modalOnClose = () => {
        setSelectedTsn(null)
    }
    const bookingCellClickEvent = (row, cellId) => {

    }
    const tsnCellClickEvent = (row, cellId) => {
        if (cellId === "statusName") {
            setSelectedTsn(row.tsn)
        }
    }
    const MatchButtonOnClick = (Row) => {
        setUMD(true);
        setMatchBooking(Row);
    }
    const DeleteButtonOnClick = (Row, Option) => {
        if (Option === "getDetails") {
            const detailInfo = {
                Tsn: Row.tsn,
                BookingId: Row.bookingId
            }
            DownloadFile(`${ApiUrl}/declaration/getsecuritydataxmlbytsnandbookingid`, JSON.stringify(detailInfo), `${detailInfo.Tsn}_${epochToSimpleDate(Date.now())} - SecurityData.xml`)
                .then((response) => {

                })
                .catch((error) => {
                    setBookingState({ ...getBookingState, errorMessage: error.ErrorDesc })
                })
            return
        }
        if (Option === "setMatched") {
            const endPoint = `${ApiUrl}/declaration/manualsetdeclarationstatus`;
            const detailInfo = {
                Tsn: Row.tsn,
                Status: "Matched"
            }
            httpClient.post(endPoint, JSON.stringify(detailInfo))
                .then(response => {
                })
                .catch(error => {
                })
                .finally(() => {
                    refreshBooking()
                })
            return
        }

        setDeleteDETsn(Row)
        setDeleteOption(Option)
        setOpenDeleteModal(true);
    }
    const onDeleteClose = () => {
        setDeleteDETsn(null)
        setOpenDeleteModal(false)
    }
    const onDeleteYesClick = () => {
        DeleteTsn(() => {
            onDeleteClose()
            refreshBooking()
        })

    }

    const refreshBooking = () => {
        getBooking(voyageId, setBookingState, getBookingState)
    }
    const onExcelClick = () => {
        setExcelModal(true);

        // reportVoyageExcel(voyageId, () => setExcelModal(true))
    }
    const onExcelClose = () => {
        // reportVoyageExcel(voyageId, () => setExcelModal(false))

        // setExcelModal(false);
    }
    const DeleteTsn = (callBack) => {
        setWaitForDelete(true)
        if (DeleteOption === "unmatch") {
            UnMatchDeclaration(callBack);
            return
        }
        const endPoint = `${ApiUrl}/declaration/deletedeclaration`;
        httpClient.post(endPoint, JSON.stringify(DeleteDETsn.tsn))
            .then(response => {
            })
            .catch(error => {
            })
            .finally(() => {
                if (typeof callBack === "function")
                    callBack()
                setWaitForDelete(false)
            })
    }
    const UnMatchDeclaration = (callBack) => {
        const endPoint = `${ApiUrl}/declaration/ManualUnmatchDeclarations`;
        const Parameters = {
            VoyageId: DeleteDETsn.voyageId,
            BookingId: DeleteDETsn.bookingId,
            TsnList: [
                DeleteDETsn.tsn
            ]
        }
        httpClient.post(endPoint, JSON.stringify(Parameters))
            .then(response => {

            })
            .catch(error => {
            })
            .finally(() => {
                if (typeof callBack === "function")
                    callBack()
                setWaitForDelete(false)
            })
    }
    const onSelectedDeclarationMatch = (tsnList) => {
        if (tsnList === null || tsnList === "" || tsnList === undefined) {
            setUMD(false)
            return;
        } else if (tsnList.length === 0) {
            setUMD(false)
            return
        }
        const bookingId = MatchBooking.bookingId
        const request = {
            voyageId: voyageId,
            bookingId: bookingId,
            tsnList: tsnList
        }
        WaitForUnmatchToMatch(true)
        const endPoint = `${ApiUrl}/declaration/manualmatchdeclarations`;
        httpClient.post(endPoint, request)
            .then((response) => {
                setUMD(false);
                refreshBooking()
            })
            .catch((error) => {
            })
            .finally(() => {
                WaitForUnmatchToMatch(false)
            })
    }
    const rowClickEvents = [bookingClickEvent, TsnClickEvent];
    const cellClickEvents = [bookingCellClickEvent, tsnCellClickEvent];
    const controlButtonEvents = [MatchButtonOnClick, DeleteButtonOnClick];


    const excelExport = () => {
        downloadBookingExcel(voyageId, `${voyageNumber} Report.xlsx`, () => setExcelModal(false))
        // setProcessExcel(true)
        // reportVoyageExcel(voyageId, (responseData) => {
        //     setProcessExcel(false)
        //     //....
        //     const reportVoyageData = responseData
        //     const dataOfVoyage = allDataOfVoyage ? allDataOfVoyage.voyage : [] //ExtraData

        //     const ExcelData = {
        //         bookingsSheet: JustExcelExtraDataBinder(bookingData, dataOfVoyage),
        //         reportVoyageSheet: reportVoyageData,
        //         // denemeSheet: [{ deneme01: "aaa\x09asdfa\x0Dsdfnbb" }],
        //     }
        //     JustExcelExport(`${voyageNumber} Bookings`, BookingExcelSchema, ExcelData)
        //     // setDownloadExcel(true)
        //     setExcelModal(false)
        // })
    }

    const toastMessageIfNoBookingsSelected =  () => {
        toast.warning('No Bookings were selected.', {
            position: toast.POSITION.TOP_CENTER
        });
    }

    const checkIfNoSelectedBookingForDelete = () => {
        if(BookingIdsDelete.length === 0){
            toastMessageIfNoBookingsSelected();
        }
        if(BookingIdsDelete.length !== 0){
            setOpenDeleteBookingsModal(true)
        }
    }

    return (
        <div className="bookingPage">
            {
                <ExcelModal
                    OpenModal={OpenExcelModal}
                    Title="Export Excel "
                    Message={`Do you want to export ${voyageNumber} Report?`}
                    OnClose={() => { setExcelModal(false); setProcessExcel(false); }}
                    onYesClick={() => excelExport()}
                    waitForRequest={ExcelDownloadStatus}
                // yesButtonComp={realExcelButton}
                />

            }
            {
                <DeleteBookingsModal
                    OpenModal={OpenDeleteBookingsModal}
                    Title="Delete Bookings "
                    OnClose={() => { setOpenDeleteBookingsModal(false); }}
                    onYesClick= {() => deleteBookings(BookingIdsDelete,getUsername()).finally(() => {refreshBooking(); setOpenDeleteBookingsModal(false)})}                    
                    waitForRequest={WaitForDeleteBookings}
                    Message={(BookingIdsDelete.length === 1 
                        ? "Are you sure you want to delete the selected booking?" 
                        : `Are you sure you want to delete the selected ${BookingIdsDelete.length} bookings?`)
                    }
                />
            }   
            {
                DeleteDETsn &&
                <DeleteTsnModal
                    OpenModal={OpenDeleteModal}
                    OnClose={onDeleteClose}
                    Title={DeleteOption === "delete" ? "Delete Duplicate Declaration" : "Unmatch Declaration"}
                    onYesClick={onDeleteYesClick}
                    waitForRequest={waitForDelete}
                    Data={DeleteDETsn}
                    Message={"Do you want to " + DeleteOption + " " + DeleteDETsn.tsn + "?"} />
            }
            {
                UnmatchedDeclarionsOpen &&
                <UnmatchDec
                MatchBooking ={MatchBooking}
                    DepartureDataEp={bookingData[0].departureDateEpoch}
                    Open={UnmatchedDeclarionsOpen}
                    OnMatchButtonClick={onSelectedDeclarationMatch}
                    OnClose={() => UnmatchedDeclarations_onCloseEvent()}
                    Title="Match declarations below" />
            }
            {
                selectedTsn &&
                <TsnHistory Tsn={selectedTsn} onClose={modalOnClose} />
            }
            <Breadcrumb label=">" className="Breadcrumb-Bootstrap">
                <Breadcrumb.Item onClick={() => pageBack(clearBooking, filterByMatch)}>Voyages</Breadcrumb.Item>
                <Breadcrumb.Item active>{"Bookings"}</Breadcrumb.Item>
            </Breadcrumb>

            <div className="bookingTableContainer">
                <BookingTableHeader
                    VoyageInfo={voyageInfo}
                    GetBookingState={getBookingState}
                    SetBookingState={setBookingState}
                    GetBookings={getBooking}
                    OnDeleteButtonClick={checkIfNoSelectedBookingForDelete}
                    BookingData={bookingData}
                    OnExcelClick={onExcelClick}
                    VoyageSummaryData={allDataOfVoyage}
                    WaitForRequest={getBookingState.waitForRequest}
                    PermissionToUseButtonDelete = {IsAdmin()}
                />
                <BookingTable
                    GetBookingState={getBookingState}
                    BookingData={bookingData}
                    OnRowClicks={rowClickEvents}
                    StaticTable={false}
                    OnCellClicks={cellClickEvents}
                    ControlButtonEvents={controlButtonEvents}
                    onExcelClick={onExcelClick}
                    onExcelClose={onExcelClose}
                    VoyageNumber={voyageNumber}
                    VoyageData={allDataOfVoyage && allDataOfVoyage.voyage}
                />
                <div>
                    <ToastContainer />
                </div>
            </div>
            
        </div>
    );
}
const pageBack = (clearBooking, filterByMatch) => {
    //clearing while backing page
    filterByMatch(null);
    clearBooking();
    history.push("/voyages")
}

const mapStateToProps = state => {
    return {
        bookingData: state.booking.bookingData,
        allDataOfVoyage: state.booking.allData,
        OpenExcelModal: state.booking.ExcelModal,
        ExcelDownloadStatus: state.booking.ExcelDownloadStatus,
        reportVoyages: state.booking.reportsData,
        BookingIdsDelete: state.booking.SelectedBookingIdsForDelete,
        WaitForDeleteBookings: state.booking.WaitForDeleteBookings
    }
}
export default connect(mapStateToProps, {
    deleteBookings, getBooking, setExcelModal, clearBooking, reportVoyageExcel, 
    filterByMatch, WaitForUnmatchToMatch, setProcessExcel, downloadBookingExcel
})(Booking);