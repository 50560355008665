import React from 'react';
import { SvgAnimatedSpinner, SvgDfdsLogo } from '@dfds-ui/icons';
import './Spindiv.css';
import { CenteredSpinner } from '@dfds-ui/react-components';
const SpinDiv = props => {
    const {waitForRequest,ShowLogo,Size} = props;
    const CspinStyle = {
        marginTop: Size ? Size / 2 : "32.5" + "px",
        marginLeft : Size ? -Size / 2 : "-32.5" + "px"
    }
    return (
        <>
            <div className={"spinContainer " + (waitForRequest ? "show" : "hide")}> 
                {ShowLogo && <SvgDfdsLogo className="dfdsLogo" height="50px" />}
                <CenteredSpinner className="centeredSpinner" instant={true} size={(Size ? Size + "px" : "65px")} />
            </div>
            <div className={"spinDiv " + (waitForRequest ? "show" : "hide")}>
            </div>
        </>
    );
}
//
//we have 2 container because i dont want to effect opacity to spinner and logo.
export default SpinDiv;