import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './Header.css';
import history from '../../core/history';
import { logOutUser, userOnline } from '../../store/action/userActions'
import { clearBooking } from '../../store/action/bookingAction'
import { clearVoyage } from '../../store/action/voyageAction'
import { AccountSignedOut } from '@dfds-ui/icons';
import { Drawer, ListItem, ListText } from '@dfds-ui/react-components';
import { getUsername, getRoles } from '../../core/authentication'
import JustNavMenu from '../CommonUI/JustNavMenu/JustNavMenu';

const Header = ({ token, logOutUser, userOnline, clearBooking, clearVoyage, pageName }) => {
    const [openDialog, setDialog] = useState(false)
    const _onVoyagePageClick = () => {
        history.push("/voyages")
    }
    const _onDeclarationPageClick = () => {
        history.push("/declarations")
    }
    const checkPagename = () => {
        if (pageName === "VOYAGES" || pageName === "DECLARATIONS" || pageName === "ADMINPANEL") {
            return true
        }
        return false
    }
    useEffect(userOnline, [])
    return (
        <nav className='navbar navbar-expand-lg navbar-static-top'>
            {renderLogo()}
            {
                checkPagename() &&
                <div className="JustNavMenu-MenuContainer">
                    <JustNavMenu onClick={_onVoyagePageClick} NavMenuText={"VOYAGES"} NavActive={pageName === "VOYAGES"} />
                    <JustNavMenu onClick={_onDeclarationPageClick} NavMenuText={"DECLARATIONS"} NavActive={pageName === "DECLARATIONS"} />
                </div>
            }
            {renderNavbarToggler()}
            {renderLogout(token, logOutUser, openDialog, setDialog, clearBooking, clearVoyage)}
        </nav>
    )
}
const renderLogo = () => {
    return (
        <div className='navbar-brand logo' onClick={() => history.push("/")}>
            <span className='d-sm-block d-md-none text-left'>
                <b>
                    <img className='img-responsive ml-3 logo-small-img' alt='' src='/image/logo-small.png' />
                </b>
            </span>
            <span className='d-none d-md-block'>
                <b>
                    <img className='img-responsive logo-lg-img' alt='' src='/image/logo.png' />
                </b>
            </span>
        </div>
    )
}

const renderNavbarToggler = () => {
    return (
        <>
            <button
                type='button'
                className='navbar-toggler'
                data-toggle='collapse'
                data-target='#navbarResponsive'
                aria-controls='navbarResponsive'
                aria-expanded='false'
                aria-label='Toggle navigation'
            >
                <span className='navbar-toggler-icon'></span>
            </button>
        </>
    )
}
const renderLogout = (token, logOutUser, openDialog, setDialog, clearBooking, clearVoyage) => {
    const userRoles = getRoles()
    return (
        (localStorage.getItem("ENS_access_token") != null && localStorage.getItem("ENS_access_token") != "" && token != null) &&
        <div className="User-Header" onClick={() => { setDialog(!openDialog)/*logOutUser(); history.push("/login")*/ }}>

            <span id="Username-Header">{getUsername(token)}</span>
            <AccountSignedOut width={30} height={30} color="#ffffff" fill="#68b2e3" />
            {openDialog &&
                <Drawer className="Drawer-ENS" position='right'>
                    {
                        userRoles === "Admin" &&
                        <ListItem
                            onClick={() => {
                                history.push("/adminpanel")
                            }}
                            clickable noBorder >
                            <ListText bold={true}>Admin Panel</ListText>
                        </ListItem>
                    }
                    <ListItem
                        onClick={() => {
                            logOutUser();
                            clearBooking();
                            clearVoyage();
                            history.push("/login")
                        }}
                        clickable noBorder >
                        <ListText bold={true}>Logout</ListText>
                    </ListItem>

                </Drawer>}
        </div >

    );
}
const mapStateToProps = state => {
    return {
        token: state.user.token,
        pageName: state.page.pageName
    };
}

export default connect(mapStateToProps, { clearBooking, clearVoyage, logOutUser, userOnline })(Header);




