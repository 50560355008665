import pageTypes from "../type/page";

export const pageChanged = (pageName) => dispatch => {

    dispatch({
        type: pageTypes.PAGE_CHANGED,
        pageName: pageName
    })

}

