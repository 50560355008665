import React from 'react';
import './Footer.css';
import ProjectConfig from '../../config/config';

const Footer = () => {
    return (<footer className="main-footer">
        <div className="row pl-3 pr-3">
            <strong>Copyright © 2019 DFDS</strong> <span className="pl-2">All rights reserved.</span>
            <div className="ml-auto">
                <b>Version</b> {ProjectConfig.Version}
            </div>
        </div>
    </footer>);
};

export default Footer; 