import FileDownload from 'js-file-download'
import ProjectConfig from '../config/config';
const DownloadFile = (ApiUrl, Body, FileName, FinallyFunction, ResponseWait) => {
    var promise = new Promise(function (resolve, reject) {

        const AccessToken = localStorage.getItem(`${ProjectConfig.projectName}_access_token`);
        fetch(ApiUrl,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + AccessToken,
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "Accept",

                },
                body: Body
            }).then(response => {
                const hasError = !response.ok;
                if (response.status === 404) {
                    reject({
                        ErrorCode: 404,
                        ErrorDesc: "Cannot reach to endpoint."
                    })
                    return
                }
                if (hasError) {
                    response.json().then(data => {
                        reject({
                            ErrorCode: data.errors[0].code,
                            ErrorDesc: data.errors[0].description
                        })
                    })

                } else {
                    response.blob().then(response => {
                        FileDownload(response, FileName)

                        resolve("OK")
                    })
                }
            }).finally(() => {
                if (typeof (FinallyFunction) === "function")
                    setTimeout(() => {
                        FinallyFunction()
                    }, ResponseWait)
            })
    })
    return promise;
}
export default DownloadFile;