import React, { useState, useRef, useEffect } from 'react'
import './HeaderCell.scss'
import PropTypes from 'prop-types';
import LeftCalc from '../../../Helper/LeftCalculator'
import JustTooltip from '../../../../JustTooltip/JustTooltip';
import { AiOutlineQuestionCircle } from "react-icons/ai"    ;
import JustSorter from '../../../Helper/Component/JustSorter/JustSorter';

const HeaderCell = (props) => {
    const { ColumnDesign, ColumnDesigns, designIndex, onSelectall,
        Selection, SelectionPermission, DataArray, AfterSort, ParentIds, TableBusy } = props;
    const headerCellText_Ref = useRef()
    const incoIcon_Ref = useRef()
    let sorter_Ref = null
    const [columnnStyle, setColumnStyle] = useState(null)
    const columnId = ColumnDesign.columnId;
    const columnOptions = ColumnDesign.Options
    const designLock = columnOptions ? (columnOptions.DesignLock) : false
    useEffect(() => {
        if (designLock) {
            if (designLock.Default) {
                const totalWidth = LeftCalc(ColumnDesigns, designIndex)
                setColumnStyle({
                    position: "sticky",
                    left: totalWidth + "px",
                    background: "#002b45",
                    width: designLock.Width,
                    zIndex: ColumnDesigns.length - designIndex
                })
            }
        }
    }, [])

    const [Dialog, setDialog] = useState(false)
    const [ToolTip, setToolTip] = useState(false)
    const _onClick = (e) => {
        setDialog(!Dialog)
    }
    // const _onDialogBlur = (e) => {
    //     setDialog(false)
    // }
    const _onMouseLeave = (e) => {
        setDialog(false)
        setToolTip(false)
    }
    const _toolTipBlur = (e) => {
        setToolTip(false)
    }
    const _onMouseEnter = (e) => {
        setToolTip(true)
    }
    const sortableHeader = DataArray && DataArray.length > 0 && columnOptions && columnOptions.Sorter
    return (

        <th
            data-field={columnId}
            data-lock={designLock.Default ? "on" : "off"}
            onClick={(event) => {
                _onClick(event)
                if (sorter_Ref) {
                    if (sorter_Ref.click && typeof sorter_Ref.click === "function")
                        sorter_Ref.click()
                }
            }}
            className={"JustTable-HeaderCell " + (sortableHeader ? "Sortable-HeaderCell" : "")}
            style={columnnStyle}
        >

            {/* {Dialog && <JustDialog
                ParentRef={headerCellText_Ref}
                onMouseLeave={_onMouseLeave}
                onBlur={_onDialogBlur}
                DialogOptions={["Freeze Column", "Deneme1", "Deneme2", "Deneme3"]}
            />} */}
            {
                ColumnDesign.Info &&
                ToolTip &&
                <JustTooltip
                    ParentRef={incoIcon_Ref}
                    onMouseLeave={_onMouseLeave}
                    onBlur={_toolTipBlur}
                    ToolTipText={ColumnDesign.Info} />
            }
            {
                ColumnDesign.Info &&
                <div onMouseEnter={(e) => _onMouseEnter(e)} onMouseLeave={(e) => _onMouseLeave(e)} className="HelpIcon-Container" ref={incoIcon_Ref}>
                    <AiOutlineQuestionCircle className="JustHeader-HelpIcon" />
                </div>
            }
            {
                ColumnDesign.columnId === "JustTable-RowSelect" ?
                    SelectionPermission ?
                        <span id="JustTable-CheckBox"><input type="checkbox" onChange={(e) => onSelectall(e.target.checked)} checked={Selection} className="JustTable-RowSelect-CheckBox" /></span>
                        :
                        <span id="JustTable-CheckBox"><input type="checkbox" checked={Selection} className="JustTable-RowSelect-CheckBox" disabled /></span>
                    :
                    <span ref={headerCellText_Ref} id="HeaderCell-Text">{ColumnDesign.Text}</span>
            }
            {
                sortableHeader &&
                <JustSorter
                    sorterRef={(jS) => { sorter_Ref = jS }}
                    ColumnId={ColumnDesign.columnId}
                    DataArray={DataArray}
                    AfterSort={AfterSort}
                    ParentIds={ParentIds}
                    isProcess={TableBusy} />
            }

        </th >
    )
}
export default HeaderCell;
HeaderCell.propTypes = {
    ColumnDesign: PropTypes.object,
    ColumnDesigns: PropTypes.array,
    designIndex: PropTypes.number,
}
HeaderCell.defaultProps = {
    ColumnDesign: {},
    ColumnDesigns: [],
    designIndex: 0,
}