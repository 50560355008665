import React, { useState, useEffect } from 'react';
import { Modal } from '@dfds-ui/modal'
import './UnmatchDecTable.scss';
import PerfectTable from '../../CommonUI/Table/PerfectTable';
import { UnmatchDecTableSchema } from './UnmatchDecTableSchema'
import { Button } from '@dfds-ui/react-components';

import httpClient from "../../../core/httpclient";
import ApiUrl from '../../../core/url'

import SpinDiv from '../../CommonUI/Spindiv/Spindiv'
import UnmatchNotFound from '../../CommonUI/TableError/TableError';
import NetworkError from '../../CommonUI/TableError/TableError';
import { AlertWarning } from '@dfds-ui/icons';
import { connect } from 'react-redux';

import { bookingPageLanguage } from '../../../assets/language/language'
import SpinButton from '../../CommonUI/SpinButton/SpinButton';
import { setMatchData } from "../../../store/action/bookingAction"
import JustList from '../../CommonUI/JustList/JustList';
const language = "en"

const UnmatchDecTable = (props) => {
    const { Open, Title, OnClose, DepartureDataEp,
        OnMatchButtonClick, WaitForUnmatchMatch, setMatchData, matchData,MatchBooking } = props;
    const onButtonClick = () => {
        if (WaitForUnmatchMatch) return
        const checkList = UnmatchDecTableSchema.Properties.filter((props) => props.checked)
        const tsnList = checkList.map((declaration) => declaration.data.tsn)
        OnMatchButtonClick(tsnList)
    }
    const AnimationInitial = {
        NoUnmatch: false,
        NoConnection: false,
        WaitForRequest: true
    }
    const [AnimationControl, setAnim] = useState(AnimationInitial)
    useEffect(() => {
        if (Open) {
            setAnim(AnimationInitial)
            setUnmatchData(null);
            getUnmatchedDeclarations()
        }
    }, [Open])
    const [UnmatchData, setUnmatchData] = useState(null);
    const getUnmatchedDeclarations = () => {
        const endPoint = `${ApiUrl}/declaration/getunmatcheddeclarations`;
        httpClient.post(endPoint, JSON.stringify(DepartureDataEp))
            .then(response => {
                setUnmatchData(response.data.result);
                setAnim({ NoUnmatch: (response.data.result < 1), NoConnection: false, WaitForRequest: false });
            })
            .catch(error => {
                setUnmatchData(null)
                setAnim({ NoUnmatch: false, NoConnection: true, WaitForRequest: false });
            })
    }
    const NoConnectionIco = <AlertWarning className="searchLogo" width="50px" height="50px" />
    const rowDivs = [
        {
            show: AnimationControl.NoUnmatch,
            component: <UnmatchNotFound ShowLogo={true} Message={bookingPageLanguage[language].UnmatchDeclarationTable.NoDeclaration} />
        },
        {
            show: AnimationControl.NoConnection,
            component: <NetworkError Logo={NoConnectionIco} ShowLogo={true} Message={bookingPageLanguage[language].UnmatchDeclarationTable.NoConnection} />
        },
        {
            show: AnimationControl.WaitForRequest,
            component: <SpinDiv waitForRequest={AnimationControl.WaitForRequest} ShowLogo={false} Size={50} />
        },
    ]
    const selectedUnitNumbers = () => {
        return matchData.map(data => data.unitNumber);
    }
    const setCheckedData = (mData, deleteInfo) => {
        var newMatchData = JSON.parse(JSON.stringify(matchData));
        if (Array.isArray(mData)) {
            newMatchData = []
            if (!deleteInfo) {
                for (var i = 0; i < mData.length; i++) {
                    newMatchData.push(mData[i])
                }
            }
        }
        else {
            if (deleteInfo) {
                for (var i = 0; i < newMatchData.length; i++) {
                    if (newMatchData[i].tsn === mData.tsn) {
                        newMatchData.splice(i, 1)
                    }
                }
            } else {
                newMatchData.push(mData);
            }
        }
        setMatchData(newMatchData);
    }
    return (
        <Modal className="UnmatchedDeclarationModal" isOpen={Open} heading={
        <span 
        className="UMDModalTitle">
            {Title}
            <span className="SelectedUnitNumber">
                {" ( "+MatchBooking.unitNumber+" )"}
            </span>
            {/* <span className="UnitNumberList">
                <JustList
                justList={selectedUnitNumbers()}
                />
            </span> */}
        </span>
    }
            onRequestClose={() => { OnClose() }}>
            <div className="gridContainer">
                <PerfectTable
                    className="UnmatchTable"
                    DataArray={UnmatchData}
                    TableSchema={UnmatchDecTableSchema}
                    RowRenderComps={rowDivs}
                    setCheckedData={setCheckedData}
                    PermissionToDelete={true}
                />
            </div>
            {
                UnmatchData && UnmatchData.length > 0 &&
                <SpinButton
                    waitForRequest={WaitForUnmatchMatch}
                    ButtonLabel={"MATCH"}
                    className="matchUnmatchBtn"
                    onClick={onButtonClick} />
            }
        </Modal>
    );
}

const mapStateToProps = state => {
    return {
        WaitForUnmatchMatch: state.booking.WaitForUnmatchMatch,
        matchData: state.booking.matchData,
    }
}
export default connect(mapStateToProps, { setMatchData })(UnmatchDecTable);
