import React from 'react';
import LoginForm from '../component/LoginForm/LoginForm'
import { Route, Redirect } from 'react-router-dom';
import { isTokenExist } from '../core/authentication'
import { pageChanged } from '../store/action/pageActions';
import { connect } from 'react-redux'

//Dummy for now just routing
const Login = (props) => {
    const { pageChanged } = props
    pageChanged("LOGIN")
    if (isTokenExist()) {
        return <Route render={props => (
            <Redirect to={`/`} />
        )} />
    } else {
        return <LoginForm />
    }
}
export default connect(null, { pageChanged })(Login);
